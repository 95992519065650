import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkCookie } from 'core/ducks/profile';

class GeoServer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			permissionChecked: false,
		};
		this.iframeRef = React.createRef();

		this.handleIframeLoad = this.handleIframeLoad.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(
			checkCookie()
		).then(() => {
			this.setState({permissionChecked: true});
		});
	}

	handleIframeLoad() {
		const iFrame = this.iframeRef.current;
		if (iFrame.contentDocument.body)
			iFrame.height = iFrame.contentDocument.body.scrollHeight;
	}

	render() {
		if (!this.state.permissionChecked)
			return null;

		return (
			<iframe ref={this.iframeRef} title="GeoServer" className="w-100" src={'/geoserver/web/'} scrolling="no" onLoad={this.handleIframeLoad}/>
		);
	}
}

GeoServer = connect(null)(GeoServer);

export default GeoServer;
