import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink,
	ListGroup, ListGroupItem, Row, Col, Label, Form, FormGroup, FormFeedback, Button,
} from 'reactstrap';
import { Map as LeafletMap, Marker, TileLayer, Popup } from 'react-leaflet';
import VisibilitySensor from 'react-visibility-sensor';

import { requestData } from 'core/ducks/list';
import { updateData } from 'core/ducks/update';
import { getValidation, validate, clearMessages } from 'core/ducks/forms';
import { Input, Loading, SecureContent } from 'core/components';
import { Alert } from 'core/views/modals';
import * as roles from 'core/model/roles';
import { ErrorPage } from 'core/views/pages';
import { buildPath } from 'core/model/lib/urlTools';
import { metadata, fileInputs, reviewData } from '../../model/lib';
import { GeoJsonViewer } from '../../components';
import ApplicationReview from '../../components/applicationReview';
import { DynamicRoutes } from '../../model/routes';
import { AssignModal } from '../modals';
import { Address, File, DateInput, PolygonInput } from 'input-fields';
import { DxfReport, SelectDepartment, SelectCategory, ScaleInput, CommunityInput, OtInput } from '../../components';
import { ro } from 'date-fns/locale';

const InputComponent = (props) => {
	const { role, isRequired, center, ...attrs } = props;
	switch (props.name) {
		case 'address':
			return <Address locale="el" showCoordinates={false} center={center} {...attrs}/>;

		case 'scale':
			return <ScaleInput {...attrs}/>;

		case 'kind':
			return <SelectCategory {...attrs}/>;

		case 'date':
			return <DateInput {...attrs}/>

		case 'community':
			return <CommunityInput required={isRequired} onChange={attrs.onChange} name={attrs.name} defaultValue={attrs.value.split(', ')}/>

		case 'ot':
			return <OtInput required={isRequired} onChange={attrs.onChange} name={attrs.name} defaultValue={attrs.value.split(', ')}/>;

		case 'srs':
			if (role === roles.AUTHORIZED)
				return <Input {...attrs} disabled />

		default:
			return <Input {...attrs}/>;
	}
}

class Application extends Component {

	constructor(props) {
		super(props);

		this.initialValues = Object.keys(metadata)
			.reduce((obj, key) => ({
				...obj,
				[key]: metadata[key].type==='address'
					? {address: '', location: {}}
					: '',
			}), {});
		this.initialValues.srs = 'ΕΓΣΑ87';

		this.state = {
			values: {
				...this.initialValues
			},
			application: props.match.params.application,
			activeTab: props.user.role===roles.AUTHORIZED ? 2 : 0,
			mounted: false,
			httpStatus: 200,
			isModalOpen: false,
			isAssignModalOpen: false,
			review: (this.props.match.params[0] && this.props.match.params[0] === 'review')
		};
		this.mapRef = React.createRef();

		this.toggleTab = this.toggleTab.bind(this);
		this.claimReview = this.claimReview.bind(this);

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.props.dispatch( getValidation('metadata') );
		const { application } = this.state;
		this.props.dispatch(
			requestData('data', `application/token/${application}`)
		).then((data) => {
			if(!data.metadata.ot){
				data.metadata.ot = '- (Χωρίς Ο.Τ.)';
			}
			data.metadata.address = {address: data.metadata.address_raw, location: String(data.metadata.lat) + ';' + String(data.metadata.lon)}
			this.setState({
				values: {
					...data.metadata
				}
			})
			if (this.state.review && (this.props.user.uuid !== data.info.reviewer_uuid || data.info.status !== 'under_review'))
				this.props.history.push(buildPath(DynamicRoutes.ApplicationAdmin, [application]));
			this.setState({
				mounted: true,
			});
		}).catch(httpStatus => {
			this.setState({httpStatus});
		});
		if (this.state.review)
			this.setState({activeTab: 2});
	}

	toggleTab(index) {
		this.setState({
			activeTab: index,
		});
	}

	claimReview() {
		const { application } = this.state;
		const { dispatch, user } = this.props;
		dispatch(
			updateData(`application/token/${application}`, {reviewer: user.uuid})
		).then(() => {
			this.props.history.replace(buildPath(DynamicRoutes.ApplicationReview, [application]));
		}).catch((err) => {
			console.warn(err);
		});
	}

	handleChange(event) {
		const { name, type, value } = event.target;
		const { messages, dispatch } = this.props;
		if (messages[name] !== '')
				dispatch( clearMessages(name) );
		this.setState({
			values: {
				...this.state.values,
				[name]: (type === 'checkbox' || type === 'switch') ? event.target.checked : value
			},
			underSubmit: false
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		const { values } = this.state;
		dispatch(
			validate(this.state.values, rules, 'metadata', this.initialValues)
		).then(()=>{
			let ot = values.ot;
			if (!values.ot.includes('["') && values.ot !== '[]' ) {
				const tmp = values.ot.split(', ')
				let text = '[';
				tmp.forEach(e => {
					text += '"' + e + '", ';
				})
				ot = text.slice(0, -2) + "]"
			}

			const data = {
				request: values.request,
				title: values.title,
				description: values.description,
				scale: values.scale,
				kind: values.kind,
				date: values.date,
				srs: values.srs,
				area: values.area,
				community: values.community,
				ot: ot,
				address: values.address,
				comment: values.comment,
				token: values.token
			}

			dispatch(
				updateData(`metadata`, {data})
			)
		})
	}

	render() {

		const { activeTab, httpStatus, isModalOpen, review, values } = this.state;
		const { rules, messages, data, user } = this.props;

		if (this.props.validationPending || this.props.validationScope !== 'metadata' || this.state.pending)
			return (<Loading />);

		if (httpStatus !== 200)
			return (<ErrorPage status={httpStatus}/>);
		if (this.props.pending || !this.state.mounted)
			return (<Loading/>);

		const tabs = (user.role===roles.AUTHORIZED && !data.review) ? ['Αρχεία', 'Περιγραφικά Δεδομένα', 'Ολοκλήρωση'] : ['Αρχεία', 'Περιγραφικά Δεδομένα'];
		if (review || data.review)
			tabs.push('Αξιολόγηση');

		const latLng = {
			lat: parseFloat(Number.parseFloat(data.metadata.lat).toFixed(3)),
			lon: parseFloat(Number.parseFloat(data.metadata.lon).toFixed(3))
		};

		return (
			<Form className="app-class" onSubmit={this.handleSubmit}>
				{ (!data.info.reviewer_uuid && user.administration === data.info.administration && data.info.status === 'progressing') &&
					<SecureContent role={roles.REVIEWER}>
						<React.Fragment>
							<Button className="float-right mx-3 mb-3 mt-0" color="primary" style={{fontSize: '130%'}} onClick={() => {this.setState({isModalOpen: true})}}>
								Ανάληψη Αξιολόγησης
							</Button>
							<div className="clearfix"/>
							<Alert
								isOpen={isModalOpen}
								toggle={() => {this.setState({isModalOpen: !isModalOpen})}}
								title="confirm"
								message="take_over_application_review_message"
								onConfirm={this.claimReview}
							/>
						</React.Fragment>
					</SecureContent>
				}
				{ (!review && user.uuid === data.info.reviewer_uuid && data.info.status === 'under_review') &&
					<SecureContent role={roles.REVIEWER}>
						<React.Fragment>
							<Button
								className="float-right mx-3 mb-3 mt-0"
								color="primary"
								style={{fontSize: '130%'}}
								onClick={() => {
									this.props.history.push(buildPath(DynamicRoutes.ApplicationReview, [this.state.application]));
								}}
							>
								Αξιολόγηση
							</Button>
							<div className="clearfix"/>
						</React.Fragment>
					</SecureContent>
				}
				{ (data.info.status === 'under_review' || data.info.status === 'progressing') &&
					<SecureContent role={roles.EDITOR}>
						<React.Fragment>
							<Button
								className="float-right mx-3 mb-2 mt-0"
								color="primary"
								style={{fontSize: '130%'}}
								onClick={() => {
									this.setState({isAssignModalOpen: true})
								}}
							>
								{data.info.status === 'progressing' ? 'Χρέωση' : 'Μετεχρέωση'}
							</Button>
							<div className="clearfix"/>
						</React.Fragment>
					</SecureContent>
				}
				<Card className="app-class">
					<Nav tabs>
						{tabs.map((title, index) => (
							<NavItem key={`nav_tab_item_${index}`}>
								<NavLink
									className={activeTab===index ? 'active text-info p-2' : 'border border-secondary p-2'}
									onClick={() => {this.toggleTab(index);}}
								>
									{title}
								</NavLink>
							</NavItem>
						))}
					</Nav>
					<CardBody>
						<TabContent activeTab={activeTab}>
							<TabPane tabId={0}>
								{ data.info.geojson && <GeoJsonViewer geojson={data.info.geojson} /> }
								{ Object.keys(fileInputs).map(key => {
									if (data.resources[key])
										return (
											<ListGroup key={`resource_${key}`}>
												<ListGroupItem tag="a" href={data.resources[key].url} action title="Μεταφόρτωση">
													{data.resources[key].filename}
												</ListGroupItem>
											</ListGroup>
										);
									return null;
								})}
							</TabPane>
							<TabPane tabId={1}>
								<Row>
									{Object.keys(metadata).map(key => (
										<Col
											xs={12}
											md={(metadata[key].type==='textarea' || metadata[key].type==='address') ? 12 : 6}
											key={`input_${key}`}
										>
											<SecureContent role={[roles.AUTHORIZED]}>
											  <Label className="application-label">{metadata[key].label}</Label>
												{ key==='address' ?
													<React.Fragment>
														<p>{data.metadata[key]}</p>
														<VisibilitySensor onChange={() => {this.mapRef.current.leafletElement.invalidateSize()}}>
															<LeafletMap
																ref={this.mapRef}
																style={{width: 100 + '%', height: 300 + 'px'}}
																center={latLng}
																zoom={17}
															>
																<TileLayer
																	url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
																	attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
																/>
																<Marker
																	position={latLng}
																	style={{width: 10 + 'px', height: 10 + 'px'}}
																>
																	<Popup>{data.metadata[key]}</Popup>
																</Marker>
															</LeafletMap>
														</VisibilitySensor>
													</React.Fragment>
													:
													<p>{key==='scale' ? '1 : ' + data.metadata[key] : data.metadata[key]}</p>
												}
											</SecureContent>
											<SecureContent role={[roles.EDITOR, roles.ADMIN, roles.REVIEWER]}>
													<FormGroup>
														<Label className="application-label" htmlFor={`input_${key}`}>{metadata[key].label}{rules[key].required && <span> *</span>}</Label>
														<InputComponent
															id={`input_${key}`}
															{...metadata[key]}
															value={values[key]}
															autoComplete="off"
															name={key}
															onChange={this.handleChange}
															placeholder={metadata[key].placeholder || metadata[key].label}
															pattern={rules[key].validation}
															valid={messages[key] === ''}
															role={user.role}
															rows={5}
															isRequired={rules[key].required}
															center={this.props.mapSettings.center}
														/>
														<FormFeedback>{messages[key] || rules[key].message}</FormFeedback>
													</FormGroup>
											</SecureContent>

										</Col>
									))}
								</Row>

								<Row>
									<Col className="text-right">
										<Button color="success" className="mr-2" type="submit">Ενημέρωση</Button>
									</Col>
								</Row>

							</TabPane>
							{ (user.role === roles.AUTHORIZED && !data.review) &&
								<TabPane tabId={2}>
									<p>Η υποβολή του αιτήματος προς τη <b>{data.info.administration}</b>, <b>{data.info.department}</b> πραγματοποιήθηκε με επιτυχία! Το αίτημά σας έλαβε κωδικό αναφοράς <b>{`${this.state.application}`}</b>.</p>
									<p>
										Μπορείτε να κατεβάσετε το αποδεικτικό{' '}
										<Link to={`/data/pdf/${this.state.application}_submit.pdf`} target="_blank">ΕΔΩ</Link>.
									</p>
									<p>Παρακαλούμε κρατήστε το για μελλοντική αναφορά.</p>
								</TabPane>
							}
							{ review &&
								<TabPane tabId={2}>
									<ApplicationReview data={data}/>
								</TabPane>
							}
							{ data.review &&
								<TabPane tabId={2}>
									{ Object.keys(data.review).map(key => (
										<Row key={`review_item_${key}`}>
											<Col>
												<Label className="application-label">{reviewData[key].label}</Label>
												{ (key === 'file' && data.review[key]) ?
													<p><Link to={data.review[key]} target="_blank">Κατεβάστε το αρχείο</Link></p>
													:
													<p>{data.review[key]}</p>
												}
											</Col>
										</Row>
									))}
									<Row>
										<Col>
											<Label className="application-label">Συνοδευτικά Αρχεία</Label>
											<ul><Link to={`/data/pdf/${this.state.application}_submit.pdf`} target="_blank">Αποδεικτικό Επιτυχούς Υποβολής</Link></ul>
											<ul><Link to={`/data/pdf/${this.state.application}_notice.pdf`} target="_blank">Αποδεικτικό Επιτυχούς Ενημέρωσης</Link></ul>
										</Col>
									</Row>
									{ (data.info.status === 'revised' && !data.info.obsolete) &&
										<Row>
											<Col className="text-right">
												<Button
													color="success"
													className="mr-2"
													onClick={() => {this.props.history.push(buildPath(DynamicRoutes.ApplyWithParent, [this.state.application]))}}
												>
													Νέα Υποβολή - Αναθεώρηση
												</Button>
											</Col>
										</Row>
									}
								</TabPane>
							}
						</TabContent>
					</CardBody>
					{ this.state.isAssignModalOpen &&
						<AssignModal
							isOpen={this.state.isAssignModalOpen}
							toggle={() => {
								this.props.dispatch(requestData('data', `application/token/${this.state.application}`));
								this.setState({isAssignModalOpen: !this.state.isAssignModalOpen});
							}}
							data={{...data.info, index: data.info.token}}
						/>
					}
				</Card>
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	rules: state.forms.validation.rules,
	validationPending: state.forms.validation.pending,
	contentPending: state.forms.pending,
	content: state.forms.content,
	validationScope: state.forms.validation.scope,
	valid: state.forms.valid,
	user: state.profile.user,
	pending: state.list.pending,
	data: state.list.data.data,
	messages: state.forms.validation_msgs,
	mapSettings: state.ui.settings.values.map,
});

Application = connect(mapStateToProps)(Application);

export default Application;
