import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTags from 'react-tag-autocomplete';
import { FormText } from 'reactstrap';

import { getData } from 'core/ducks/update';

import '../style/tags.scss';

class OtInput extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tags: [],
			value: [],
			ot: [],
			pending: false,
		};

		this.handleDelete = this.handleDelete.bind(this);
		this.handleAddition = this.handleAddition.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount() {
		if (this.props.defaultValue) {
			const tags = this.props.defaultValue
				.map(val => ({id: val, name: val}));
			this.setState({tags});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevState.tags) !== JSON.stringify(this.state.tags)) {
			let value = this.state.tags.map(tag => tag.name);
			this.setState({value});
		}

		if (prevState.value !== this.state.value && typeof this.props.onChange == 'function')
			this.props.onChange({target: {name: this.props.name, value: JSON.stringify(this.state.value)}});
	}

	handleDelete (i) {
		const tags = this.state.tags.slice(0);
		tags.splice(i, 1);
		this.setState({ tags });
	}

	handleAddition (tag) {
		const { value, tags } = this.state;
		const index = value.indexOf(tag.name);
		let newTags = index !== -1 ? [...tags.slice(0, index), ...tags.slice(index + 1)] : [...tags];
		newTags = [].concat(newTags, tag);
		this.setState({ tags: newTags });
	}

	handleInputChange(query) {
		if (query.substring(0,1) === '-') {
			this.setState({
				ot: [{id: '-', name: '- (Χωρίς Ο.Τ.)'}]
			});
			return;
		}
		if (!this.state.pending && query.length > 2) {
			this.setState({pending: true}, () => {
				this.props.dispatch(
					getData(`administrative/scope/ot/query/${query}`)
				).then(ot => {
					this.setState({
						ot: ot.map(value => ({id: value, name: value})),
						pending: false,
					});
				}).catch(err => {
					this.setState({
						pending: false,
					});
					console.warn(err);
				});
			});
		}
	}

	render() {

		const { ot, tags, value } = this.state;

		const classNames = (this.props.required && value.length === 0) ? ' is-invalid' : '';

		return (
			<>
				<ReactTags
					tags={tags}
					suggestions={ot}
					placeholder="Πληκτρολογήστε Ο.Τ."
					autofocus={false}
					allowNew={false}
					maxSuggestionsLength={10}
					minQueryLength={1}
					handleDelete={this.handleDelete}
					handleAddition={this.handleAddition}
					handleInputChange={this.handleInputChange}
					autoresize={false}
				/>
				<FormText>Σε περίπτωση μη ύπαρξης Ο.Τ. πληκτρολογήστε -</FormText>
				<input type="hidden" className={'form-control' + classNames}/>
			</>
		);
	}
}

OtInput = connect(null)(OtInput);

export default OtInput;
