import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Card, CardBody, Row, Col, Modal, ModalBody, Progress,
	Form, FormGroup, FormFeedback, FormText, Label, Button,
	TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import DxfParser from 'dxf-parser';

import * as roles from 'core/model/roles';
import { buildPath } from 'core/model/lib/urlTools';
import { uploadData, cancelUpload } from '../../ducks/upload';
import { getValidation, validate, clearMessages } from 'core/ducks/forms';
import { getData } from 'core/ducks/update';
import { Input, Loading } from 'core/components';
import { ErrorPage } from 'core/views/pages';
import { Address, File, DateInput, PolygonInput } from 'input-fields';
import { metadata, fileInputs, dxf2geojson } from '../../model/lib';
import { DynamicRoutes } from '../../model/routes';
import { DxfReport, SelectDepartment, SelectCategory, ScaleInput, CommunityInput, OtInput } from '../../components';

import '../../style/general.scss';

const InputComponent = (props) => {
	const { role, isRequired, center, ...attrs } = props;
	switch (props.name) {
		case 'address':
			return <Address locale="el" showCoordinates={false} center={center} {...attrs}/>;

		case 'scale':
			return <ScaleInput {...attrs}/>;

		case 'kind':
			return <SelectCategory {...attrs}/>;

		case 'date':
			return <DateInput {...attrs}/>

		case 'community':
			return <CommunityInput required={isRequired} onChange={attrs.onChange} name={attrs.name} defaultValue={attrs.value.split(', ')}/>

		case 'ot':
			return <OtInput required={isRequired} onChange={attrs.onChange} name={attrs.name} defaultValue={attrs.value.split(', ')}/>;

		case 'srs':
			if (role === roles.AUTHORIZED)
				return <Input {...attrs} disabled />

		default:
			return <Input {...attrs}/>;
	}
}

class Apply extends Component {

	constructor(props) {
		super(props);

		this.initialValues = Object.keys(metadata)
			.reduce((obj, key) => ({
				...obj,
				[key]: metadata[key].type==='address'
					? {address: '', location: {}}
					: '',
			}), {});
		this.initialValues.srs = 'ΕΓΣΑ87';

		this.initialFileValues = Object.keys(fileInputs)
			.reduce((obj, key) => ({
				...obj,
				[key]: ''
			}), {});

		this.initialFiles = Object.keys(fileInputs)
			.reduce((obj, key) => ({
				...obj,
				[key]: []
			}), {});

		this.initialValid = Object.keys(fileInputs)
			.reduce((obj, key) => ({
				...obj,
				[key]: null,
			}), {});

		this.requiredFiles = Object.keys(fileInputs)
			.reduce((obj, key) => ({
				...obj,
				[key]: (fileInputs[key].required.indexOf(props.user.role) !== -1),
			}), {});

		this.state = {
			values: {
				...this.initialValues,
				administration: '',
				department: '',
			},
			internal_values: {
				status: '',
				registration_number: '',
				internal: '',
			},
			fileValues: {...this.initialFileValues},
			files: {...this.initialFiles},
			valid: {...this.initialValid},
			httpStatus: 200,
			parentHttpStatus: 200,
			underSubmit: false,
			showProgress: false,
			submitted: false,
			activeTab: 0,
			dxfData: null,
			bbox: {},
			dxfReport: null,
			dxfWrongTypes: null,
			viewedSecondTab: false,
			onlyBbox: false,
			processing: false,
			pending: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.uploadData = this.uploadData.bind(this);
		this.toggleTab = this.toggleTab.bind(this);
		this.getDepartmentValues = this.getDepartmentValues.bind(this);
		this.handleInternalChange = this.handleInternalChange.bind(this);
	}

	componentDidMount() {
		this.props.dispatch( getValidation('metadata') );
		if (this.props.match.params.parent) {
			this.setState({pending: true});
			this.props.dispatch( getData(`application/token/${this.props.match.params.parent}`) )
				.then(response => {
					if ( !response.metadata.internal && response.metadata.status !== 'revised') {
						this.setState({parentHttpStatus: 404, pending: false});
						return;
					}
					this.initialValues = Object.keys(metadata)
						.reduce((obj, key) => ({
							...obj,
							[key]: metadata[key].type==='address'
								? {address: response.metadata['address_raw'], location: `${response.metadata['lat']};${response.metadata['lon']}`}
								: response.metadata[key],
						}), {});
					this.setState({
						values: {...this.state.values, ...this.initialValues, administration: response.metadata.administration},
						internal_values: {status: response.info.status || '', registration_number: response.info.registration_number || '', internal: response.info.internal || ''},
						pending: false,
					});
				})
				.catch(parentHttpStatus => {
					this.setState({parentHttpStatus, pending: false});
				});
		}
	}

	componentDidUpdate(prevProps, prevState) {

		if ( !prevState.underSubmit && this.state.underSubmit)
			this.uploadData('post');

		if (prevProps.progress < 100 && this.props.progress === 100)
			this.setState({
				showProgress: false,
				submitted: true,
			});
	}

	handleChange(event) {
		const { name, type, value } = event.target;
		const { messages, dispatch } = this.props;
		if (messages[name] !== '')
				dispatch( clearMessages(name) );
		this.setState({
			values: {
				...this.state.values,
				[name]: (type === 'checkbox' || type === 'switch') ? event.target.checked : value
			},
			underSubmit: false
		});
	}

	handleInternalChange(event) {
		const { name, value } = event.target;
		this.setState({
			internal_values: {
				...this.state.internal_values,
				[name]: value,
			}
		});
	}

	handleFileChange(event) {
		const { name, value, files } = event.target;
		this.setState({
			fileValues: {
				...this.state.fileValues,
				[name]: value,
			},
			files: {
				...this.state.files,
				[name]: files,
			},
		}, () => {
			this.validateFile(name);
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		dispatch(
			validate(this.state.values, rules, 'metadata', this.initialValues)
		).then(() => {
			const validFiles = {};
			Object.keys(this.state.files).forEach(key => {
				let file = this.state.files[key];
				if (file.length === 0)
					validFiles[key] = this.requiredFiles[key] ? 'Η υποβολή του αρχείου είναι υποχρεωτική.' : '';
			});
			this.setState({
				valid: {
					...this.state.valid,
					...validFiles,
				}
			}, () => {
				let valid = true;
				Object.values(this.state.valid).forEach(value => {
					if (value && value !== '')
						valid = false;
				});
				if (this.props.valid && valid)
					this.setState({underSubmit: true});
				if (!valid)
					this.setState({activeTab: 0});
			});
		});
	}

	resetForm() {
		this.setState({
			values: {...this.initialValues},
			fileValues: {...this.initialFileValues},
			files: {...this.initialFiles},
		});
	}

	uploadData() {
		let formData = new FormData();
		const { values, files, internal_values } = this.state;
		Object.keys(values).forEach(key => {
			if (values[key] !== '') {
				if (key === 'address') {
					formData.append('address', values[key]['address']);
					formData.append('location', values[key]['location']);
				} else {
					formData.append(key, values[key]);
				}
			}
		});
		formData.append('geojson', JSON.stringify(this.state.dxfData));
		formData.append('bbox', JSON.stringify(this.state.bbox));
		Object.keys(files).forEach(key => {
			if (files[key][0])
				formData.append(key, files[key][0]);
		});
		if (this.props.user.role !== roles.AUTHORIZED) {
			Object.keys(internal_values).forEach(key => {
				if (internal_values[key] !== '')
					formData.append(key, internal_values[key]);
			});
		}
		if (this.props.match.params.parent)
			formData.append('parent', this.props.match.params.parent);

		this.setState({showProgress: true, processing: true}, () => {
			const url = 'application';
			this.props.dispatch( uploadData(url, formData) )
				.then(response => {
					const url = buildPath(DynamicRoutes.Application, [response.token]);
					this.props.history.push(url);
					this.setState({processing: false});
				})
				.catch(error => {
					this.setState({underSubmit: false, processing: false});
					console.warn(error);
				});
		});
	}

	validateFile(type) {
		const reader = new FileReader();
		const { files } = this.state;
		if (!files[type][0]) {
			this.setState({
				valid: {
					...this.state.valid,
					[type]: this.requiredFiles[type] === true ? null : '',
				}
			});
			return;
		}
		switch (type) {
			case 'dxf':
				this.setState({processing: true, dxfData: null, dxfReport: null, dxfWrongTypes: null}, () => {
					setTimeout(() => {
						reader.readAsText(files.dxf[0]);
						reader.onload = (event) => {
							const { result } = event.target;
							const parser = new DxfParser();
							try {
								const dxf = parser.parseSync(result);
								const { geojson, report, wrongTypes } = dxf2geojson(this.props.layers, dxf);
								const invalid = wrongTypes ? 'Βρέθηκαν μη συμβατοί τύποι layer.' : (geojson.length === 0 ? 'Δεν βρέθηκε γεωμετρία.' : '');
								this.setState({
									processing: false,
									dxfData: geojson,
									dxfReport: report,
									dxfWrongTypes: wrongTypes,
									valid: {
										...this.state.valid,
										dxf: invalid,
									},
								});
							} catch(err) {
								this.setState({
									processing: false,
									valid: {
										...this.state.valid,
										dxf: 'Βρέθηκε σφάλμα στο αρχείο dxf.',
									},
								});
								console.warn(err.stack);
							}
						}
					}, 500);
				});
				break;

			case 'pdf':
				let pdf = false;
				if (this.props.user.role !== roles.AUTHORIZED) {
					pdf = true;
				} else {
					this.setState({processing: true});
					reader.readAsText(files.pdf[0]);
					reader.onload = (event) => {
						const { result } = event.target;
						pdf = result.search('adbe.pkcs7.detached') === -1 ? 'Το pdf δεν περιέχει ψηφιακή υπογραφή.' : '';
						if (pdf === '') {
							let formData = new FormData();
							formData.append('file', files.pdf[0]);
							this.props.dispatch(uploadData('signatureVerification', formData, false))
								.then(response => {
									pdf = response.valid ? '' : 'Το pdf δεν περιέχει έγκυρη ψηφιακή υπογραφή.';
								})
								.catch(err => {
									pdf = 'Παρουσιάστηκε κάποιο σφάλμα. Παρακαλούμε επαναφορτώστε την εφαρμογή και δοκιμάστε ξανά.';
									console.warn(err);
								})
								.then(() => {
									this.setState({
										processing: false,
										valid: {
											...this.state.valid,
											pdf
										}
									});
								});
						} else {
							this.setState({
								processing: false,
								valid: {
									...this.state.valid,
									pdf
								}
							});
						}
					}
				}
				break;

			default:
				this.setState({
					valid: {
						...this.state.valid,
						[type]: '',
					}
				});
			return;
		}
	}

	toggleTab(index) {
		this.setState({
			activeTab: index,
			viewedSecondTab: true,
		});
	}

	getDepartmentValues(administration, department, httpStatus=200) {
		this.setState({
			values: {
				...this.state.values,
				administration,
				department,
			},
			httpStatus,
		});
	}

	render() {

		const {
			values, internal_values, fileValues, httpStatus, parentHttpStatus, activeTab, valid, dxfData, dxfReport, dxfWrongTypes, processing, showProgress
		} = this.state;
		const { rules, messages, user } = this.props;

		if (this.props.validationPending || this.props.validationScope !== 'metadata' || this.state.pending)
			return (<Loading />);

		if (httpStatus !== 200 || parentHttpStatus !== 200)
			return (<ErrorPage status={httpStatus === 200 ? parentHttpStatus : httpStatus} />);

		if ((user.role === roles.EDITOR || user.role === roles.REVIEWER) && !user.administration)
			return (
				<Card>
					<CardBody>
						Θα πρέπει να ανήκετε σε μία Δ/νση προκειμένου να υποβάλλετε στον Μητρώο Τοπογραδικών Διαγραμμάτων.
					</CardBody>
				</Card>
			);

		return (
			<Form className="app-class" onSubmit={this.handleSubmit}>
				<Card>
					<Nav tabs>
						{['Αρχεία', 'Περιγραφικά Δεδομένα'].map((title, index) => (
							<NavItem key={`nav_tab_item_${index}`}>
								<NavLink
									className={activeTab===index ? 'active text-info p-2' : 'border border-secondary p-2'}
									onClick={() => {this.toggleTab(index);}}
								>
									{title}
								</NavLink>
							</NavItem>
						))}
					</Nav>
					<CardBody>
						<SelectDepartment
							defaultValue={this.props.match.params.parent ? values.administration : null}
							onChange={this.getDepartmentValues}
						/>
						{ user.role !== roles.AUTHORIZED &&
							<Row className="mr-2">
								<Col sm="8"/>
								<Col sm="4" className="p-0">
									<FormGroup row>
										<Label className="d-block p-0" sm="3">Αρ. Πρωτ.</Label>
										<Col sm="9" className="p-0">
											<Input className="m-0" type="text" name="registration_number" value={internal_values.registration_number} onChange={this.handleInternalChange} placeholder="Αρ. Πρωτ." />
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label className="d-block p-0" sm="3">Συντάκτης *</Label>
										<Col sm="9" className="p-0">
											<Input className="m-0" required type="select" name="internal" value={internal_values.internal} onChange={this.handleInternalChange} >
												<option value="">--- Επιλέξτε τύπο συντάκτη</option>
												<option value={true}>Δήμος</option>
												<option value={false}>Εξωτερικός</option>
											</Input>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label className="d-block p-0" sm="3">Κατάσταση *</Label>
										<Col sm="9" className="p-0">
											<Input className="m-0" required type="select" name="status" value={internal_values.status} onChange={this.handleInternalChange} >
												<option value="">--- Επιλέξτε Κατάσταση</option>
												<option value="completed">Τελικό</option>
												<option value="draft">Πρόταση</option>
											</Input>
										</Col>
									</FormGroup>
								</Col>
							</Row>
						}
						<TabContent activeTab={activeTab}>
							<TabPane tabId={0}>
								<Row>
									{ Object.keys(fileInputs).map(key => (
										<Col xs={12} key={`file_input_${key}`}>
											<FormGroup>
												<Label className="application-label" htmlFor={`file_input_${key}`}>
													{fileInputs[key].label}{this.requiredFiles[key] && <span> *</span>}
												</Label>
												<File
													id={`file_input_${key}`}
													name={key}
													label="Επιλογή αρχείου"
													accept={`.${key}`}
													value={fileValues[key]}
													onChange={this.handleFileChange}
													multiple={false}
													valid={valid[key] ? valid[key] === '' : undefined}
													feedback={valid[key] || ''}
													disabled={key==='dxf' && this.state.onlyBbox}
												/>
												<FormText>{fileInputs[key].comment}</FormText>
											</FormGroup>
											{ key === 'dxf' &&
												<DxfReport geojson={dxfData} report={dxfReport} wrongTypes={dxfWrongTypes} getBounds={(bbox) => {this.setState({bbox})}}/>
											}
											{ (key === 'dxf' && user.role !== roles.AUTHORIZED) &&
												<FormGroup check >
													<Label check >
														<Input
															type="checkbox"
															value={this.state.onlyBbox}
															checked={this.state.onlyBbox}
															onChange={(e) => {
																const { checked } = e.target;
																this.requiredFiles.dxf = !checked;
																this.setState({onlyBbox: checked});
															}}
														/>
														Εισαγωγή μόνο ορθογωνίου οριοθέτησης.
													</Label>
												</FormGroup>
											}
											{ (key === 'dxf' && this.state.onlyBbox && user.role !== roles.AUTHORIZED) &&
												<FormGroup>
													<Label className="application-label" htmlFor="bbox_input">Ορθογώνιο Οριοθέτησης *</Label>
													<PolygonInput
														addressSearch={true}
														dispatch = {this.props.dispatch}
													  locale="el"
														draw={{
															polygon: false,
															rectangle: {showArea: true, showLength: true, metric: ['km', 'm']},
															marker: false,
															polyline: false,
															circlemarker: false,
															circle: false
														}}
														center={[37.983810, 23.727539]}
														zoom={14}
														height={500}
														onChange={(e) => {
															const bbox = e.target.value[0];
															this.setState({bbox});
														}}
														name="bbox"
													/>
												</FormGroup>
											}
										</Col>
									))}
								</Row>
								<Row>
									<Col className="text-right">
										{ this.state.viewedSecondTab &&
											<Button color="success" className="mr-2" type="submit">Υποβολή</Button>
										}
										<Button type="button" className="mr-2" color="primary" onClick={() => this.toggleTab(1)}>
											<i className="fa fa-step-forward"/>{' '}Επόμενο
										</Button>
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId={1}>
								<Row>
									{ Object.keys(metadata).map(key => (
										<Col
											xs={12}
											md={(metadata[key].type==='textarea' || metadata[key].type==='address') ? 12 : 6}
											key={`input_${key}`}
										>
											<FormGroup>
												<Label className="application-label" htmlFor={`input_${key}`}>{metadata[key].label}{rules[key].required && <span> *</span>}</Label>
												<InputComponent
													id={`input_${key}`}
													{...metadata[key]}
													value={values[key]}
													autoComplete="off"
													name={key}
													onChange={this.handleChange}
													placeholder={metadata[key].placeholder || metadata[key].label}
													pattern={rules[key].validation}
													valid={messages[key] === ''}
													role={user.role}
													rows={5}
													isRequired={rules[key].required}
													center={this.props.mapSettings.center}
												/>
												<FormFeedback>{messages[key] || rules[key].message}</FormFeedback>
											</FormGroup>
										</Col>
									))}
								</Row>
								<Row>
									<Col className="text-right">
										<Button type="button" className="mr-2" color="primary" onClick={() => this.toggleTab(0)}>
											<i className="fa fa-step-backward"/>{' '}Προηγούμενο
										</Button>
										<Button color="success" className="mr-2" type="submit">Υποβολή</Button>
										<Button color="warning" className="mr-2" type="button" onClick={this.resetForm}>Επαναφορά</Button>
									</Col>
								</Row>
							</TabPane>
						</TabContent>
						<div className="float-right text-muted m-2">* Υποχρεωτικά πεδία.</div>
					</CardBody>
				</Card>
				<Modal isOpen={showProgress} className="modal-md">
					<ModalBody>
						<p>Παρακαλούμε περιμένετε τη μεταφόρτωση των δεδομένων...</p>
						<Progress animated value={this.props.progress} />
						<div className="text-right p-1">
							<Button type="button" onClick={() => {
								cancelUpload('Operation canceled by the user.');
								this.setState({showProgress: false});
							}}>
								Ακύρωση
							</Button>
						</div>
					</ModalBody>
				</Modal>
				<Modal isOpen={processing && !showProgress} className="modal-md">
					<ModalBody>
						Παρακαλούμε περιμένετε να ολοκληρωθεί η επεξεργασία των δεδομένων...
					</ModalBody>
				</Modal>
			</Form>
		);
	}

}

const mapStateToProps = (state) => ({
	rules: state.forms.validation.rules,
	validationPending: state.forms.validation.pending,
	contentPending: state.forms.pending,
	content: state.forms.content,
	validationScope: state.forms.validation.scope,
	valid: state.forms.valid,
	messages: state.forms.validation_msgs,
	progress: state.upload.progress,
	user: state.profile.user,
	mapSettings: state.ui.settings.values.map,
	layers: state.ui.settings.values.layers,
});

Apply = connect(mapStateToProps)(Apply);

export default Apply;
