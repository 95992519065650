import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import { Table, Thead, Tbody, Tr, Td } from 'css-table';
import GeoJsonViewer from './geoJsonViewer';

class DxfReport extends Component {

	render() {

		const { geojson, report, wrongTypes, getBounds } = this.props;

		if (!geojson)
			return null;

		const layers = Object.keys(report);
		const columns = [];
		columns[0] = layers.filter((key, index) => index < layers.length/2);
		columns[1] = layers.filter((key, index) => index >= layers.length/2);

		return (
			<>
				{ wrongTypes &&
					<Row>
						<Col>
							<Table>
								<Thead>
									<Tr>
										<Td>Layer</Td>
										<Td>Τύπος</Td>
										<Td>Τύπος στο dxf</Td>
									</Tr>
								</Thead>
								<Tbody>
									{ wrongTypes.map((values, index) => (
										<Tr key={`wrong_types_row_${index}`}>
											<Td>{values.layer}</Td>
											<Td>{values.correctType}</Td>
											<Td className="text-danger">{values.type}</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</Col>
					</Row>
				}
				{ geojson.length > 0 &&
					<GeoJsonViewer geojson={geojson} getBounds={getBounds} />
				}
				<Row>
					{ columns.map((column, index) => (
						<Col key={`report_table_col${index}`} className="pt-0">
							{ column.length > 0 &&
								<Table className="mb-0">
									<Thead className={index===1 ? 'd-none d-sm-table-header-group' : undefined}>
										<Tr>
											<Td>Layer</Td>
											<Td>Πλήθος</Td>
										</Tr>
									</Thead>
									<Tbody>
										{ column.map(layer => (
											<Tr key={`row_${layer}`}>
												<Td>{layer}</Td>
												<Td>{report[layer]}</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							}
						</Col>
					))}
				</Row>
			</>
		);
	}

}

DxfReport.propTypes = {
	report: PropTypes.object,
	geojson: PropTypes.array,
	wrongTypes: PropTypes.array,
	getBounds: PropTypes.func,
};

export default DxfReport;
