import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import * as L from 'leaflet';
import 'proj4leaflet';
import proj4 from 'proj4';
import VisibilitySensor from 'react-visibility-sensor';

class GeoJsonViewer extends Component {

	constructor(props) {
		super(props);
		proj4.defs("urn:ogc:def:crs:EPSG::2100", "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs");
		this.mapRef = React.createRef();

		this.updateMap = this.updateMap.bind(this);
	}

	componentDidMount() {
		this.updateMap();
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.geojson && this.props.geojson)
			this.updateMap();
	}

	updateMap() {
		const { geojson } = this.props;
		if (!geojson || geojson.length === 0)
			return;
		let leafletGeoJSON = new L.Proj.geoJson(geojson, {
			pointToLayer: (feature, latlng) => {
				return new L.CircleMarker(latlng, {
					radius: 1,
					color: '#FF0000'
				});
			},
			onEachFeature: (feature, layer) => {
				if (feature.properties.text) {
					layer.bindPopup('<b>' + feature.properties.name + '</b>: ' + feature.properties.text);
				} else {
					layer.bindPopup('<b>' + feature.properties.name + '</b>');
				}
			}
		});
		let leafletFG = this.mapRef.current.leafletElement;
		leafletGeoJSON.eachLayer( (layer) => {
			leafletFG.addLayer(layer);
		});
		const bounds = leafletGeoJSON.getBounds();
		if (typeof this.props.getBounds === 'function')
			this.props.getBounds(bounds);
		leafletFG.fitBounds(bounds);
	}

	render() {

		const { geojson, ...otherProps } = this.props;

		return (
			<VisibilitySensor onChange={() => {
				this.mapRef.current.leafletElement.invalidateSize();
				this.updateMap();
			}}>
				<LeafletMap ref={this.mapRef} style={{width: 100 + '%', height: 300 + 'px'}} minZoom={3} maxZoom={19} {...otherProps}>
					<TileLayer
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
					/>
				</LeafletMap>
			</VisibilitySensor>
		);
	}
}

GeoJsonViewer.propTypes = {
	geojson: PropTypes.array.isRequired,
	getBounds: PropTypes.func,
};

export default GeoJsonViewer;
