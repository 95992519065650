import * as roles from 'core/model/roles';
import { StaticRoutes as CoreStaticRoutes } from 'core/model/routes';

const EditDepartments = CoreStaticRoutes.Settings + '/departments';
const EditPlanCategories = CoreStaticRoutes.Settings + '/categories';
const GeoServer = CoreStaticRoutes.Settings + '/geoserver';

export const StaticRoutes = {
	EditDepartments,
	EditPlanCategories,
	GeoServer
};

export const routes = [
	{
		url: EditDepartments,
		name: 'services',
		component: 'EditDepartments',
		role: roles.ADMIN,
	},
	{
		url: EditPlanCategories,
		name: 'plan categories',
		component: 'EditCategories',
		role: roles.ADMIN,
	},
	{
		url: GeoServer,
		name: 'GeoServer',
		component: 'GeoServer',
		role: roles.ADMIN,
	}
];

const combined = {StaticRoutes, routes};

export default combined;
