import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'settings',
		role: roles.ADMIN,
		children: [
			{
				name: 'services',
				url: StaticRoutes.EditDepartments,
				icon: 'fa fa-university',
			},
			{
				name: 'plan categories',
				url: StaticRoutes.EditPlanCategories,
				icon: 'fa fa-map-o',
			},
			{
				name: 'GeoServer',
				url: StaticRoutes.GeoServer,
				icon: 'fa fa-map',
			},
		]
	},
];

export default menu;
