import React, { Component } from 'react';
import { connect } from 'react-redux';

import Input from 'core/components/input';
import { getData } from 'core/ducks/update';

class SelectCategory extends Component {

	constructor(props) {
		super(props);
		this.state = {
			categories: [],
			value: props.value || '',
		};

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(
			getData('planCategories/fields/category')
		).then((categories) => {
			this.setState({categories});
		}).catch((httpStatus) => {
			console.warn(httpStatus);
		});
	}

	handleChange(event) {
		const { value } = event.target;
		this.setState({value});
		this.props.onChange({...event});
	}

	render() {

		const { categories, value } = this.state;
		const { dispatch, ...otherProps } = this.props;

		return (
			<Input {...otherProps} type="select" value={value} onChange={this.handleChange}>
				<option value="">--- Επιλέξτε κατηγορία διαγράμματος ---</option>
				{ categories.map((category, index) => (
					<option key={`category_option_${index}`} value={category}>{category}</option>
				))}
			</Input>
		);
	}
}

SelectCategory = connect(null)(SelectCategory);

export default SelectCategory;
