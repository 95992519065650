import React, { Component } from 'react';
import { Row, Col, Label, FormGroup, InputGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { Map as LeafletMap, TileLayer, FeatureGroup, Marker } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Input } from 'core/components';
import { getData } from 'core/ducks/update';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import '../scss/style.scss';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const getLayerType = (layer) => {
	if (layer instanceof L.Marker)
		return 'point';
	if ((layer instanceof L.Polyline) && ! (layer instanceof L.Polygon))
		return 'line';
	if (layer instanceof L.Polygon)
		return 'polygon';
};

class PolygonInput extends Component {

	constructor(props) {
		super(props);

		this.editControlRef = React.createRef();

		this.initialValues = {
			street: '',
			house_number: '',
			town: '',
			zip: '',
		};

		this.state = {
			polygon: {},
			line: {},
			point: {},
			values: {
				...this.initialValues,
			},
			geoCoding: {},
			geoCodeID: -1,
			marker: null
		};

		this.fields = ['street', 'house_number', 'town', 'zip'];
		this.handleChange = this.handleChange.bind(this);
	}

	_onEdited = (e) => {
		let changes = {
			'polygon': {},
			'line': {},
			'point': {},
		};
		e.layers.eachLayer(layer => {
			const scope = getLayerType(layer);
			changes[scope][layer._leaflet_id] = layer._latlngs;
		});
		this.setState({
			polygon: {...this.state.polygon, ...changes['polygon']},
			line: {...this.state.line, ...changes['line']},
			point: {...this.state.point, ...changes['point']},
		}, this._onChange);
	}

	_onCreated = (e) => {
		let { layer } = e;
		const scope = getLayerType(layer);
		this.setState({
			[scope]: {...this.state[scope], [layer._leaflet_id]: layer._latlngs || layer._latlng}
		}, this._onChange);
	}

	_onDeleted = (e) => {

		let ids = {};
		e.layers.eachLayer(layer => {
			ids[layer._leaflet_id] = true;
		});
		let polygon, line, point;
		['polygon', 'line', 'point'].forEach(feature => {
			let newList = Object.keys(this.state[feature])
				.filter(id => !ids[id])
				.reduce((obj, id) => ({
					...obj,
					[id]: this.state[feature][id]
				}), {});
			if (feature === 'polygon') {
				polygon = newList;
			} else if (feature === 'line') {
				line = newList;
			} else {
				point = newList;
			}
		});

		this.setState({
			polygon,
			line,
			point,
		}, this._onChange);
	}

	_onChange = () => {

		if (!this._editableFG)
			return;

		const value = this.props.returnGeoJSON ?
			this._editableFG.leafletElement.toGeoJSON() : [
				...Object.values(this.state.polygon),
				...Object.values(this.state.line),
				...Object.values(this.state.point),
			];
		this.props.onChange({
			target: {
				name: this.props.name,
				value
			}
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.disabled && !this.props.disabled) {
			this.setState({
				polygon: {}
			});
			this.removeAllLayers();
		}
	}

	componentWillUnmount() {

	}

	removeAllLayers() {
		const layerContainer = this.editControlRef.current.leafletElement.options.edit.featureGroup;
		const layers = layerContainer._layers;
		Object.keys(layers).forEach(id => {
			const layer = layers[id];
			layerContainer.removeLayer(layer);
		});
	}

	handleChange(event) {
		const { target } = event;
		const values = {
			...this.state.values,
			[target.name]: target.value
		};
		this.setState({values});

		const value = this.fields.map(field => values[field].trim()).join('+');
		const completed = Object.values(values).filter(elem => elem.trim().length > 0).length === 4;
		if (completed) {
			if (this.state.geoCodeID !== -1) {
				clearTimeout(this.state.geoCodeID);
			}
			const geoCodeID = setTimeout(() => {
				const address = encodeURI(value);
				this.props.dispatch(
					getData(`geocode?address=${address}`)
				).then(json => {
					if (json.results.length > 0) {
						const { lat, lng } = json.results[0].geometry.location;
						this.setState({
							geoCoding: {lat, lng},
							center: [lat, lng],
							zoom: 18,
							marker: <Marker position={[lat, lng]} style={{width: 10 + 'px', height: 10 + 'px'}}/>
						});
					}
				});
				this.setState({geoCodeID: -1});
			}, 700);
			this.setState({geoCodeID});
		}
	}

	i18n = {
		'en': {
			street: 'Street',
			number: 'Number',
			town: 'Town',
			zip: 'Zip'
		},
		'el': {
			street: 'Οδός',
			number: 'Αριθμός',
			town: 'Πόλη',
			zip: 'Ταχ. Κωδικός'
		}
	}

	render() {

		let { locale, dispatch } = this.props;

		const { values } = this.state;

		return (
			<Row>
				<Col className="pt-0">
					{ this.props.label &&
						<Label className="mb-0">{this.props.label}</Label>
					}
					{
						this.props.addressSearch && <FormGroup tag="fieldset">
							<Row form >
								<Col md="10" lg="6">
									<Input
										id="address_street"
										placeholder={this.i18n[locale].street}
										name="street"
										value={values.street}
										onChange={this.handleChange}
										pattern="[a-zA-Zα-ωΑ-Ωέύϋΰίϊΐόάήώ.\ ]+"
									/>
								</Col>
								<Col md="2" lg="1">
									<Input
										id="address_house_number"
										placeholder={this.i18n[locale].number}
										name="house_number"
										value={values.house_number}
										onChange={this.handleChange}
										pattern="[0-9]+[ΑΒΓΔαβγδ]{0,1}"
									/>
								</Col>
								<Col md="9" lg="3">
									<Input
										id="address_town"
										placeholder={this.i18n[locale].town}
										name="town"
										value={values.town}
										onChange={this.handleChange}
										pattern="[a-zA-Zα-ωΑ-Ωέύϋΰίϊΐόάήώ.\ ]+"
									/>
								</Col>
								<Col md="3" lg="2">
									<InputGroup>
										<Input
											id="address_zip"
											placeholder={this.i18n[locale].zip}
											name="zip"
											value={values.zip}
											onChange={this.handleChange}
											pattern="[0-9]{3}[\ ]{0,1}[0-9]{2}"
										/>
										<i
											className="fa fa-times pt-2 px-2 cursor-pointer"
											onClick={() => {
												this.setState({
													values: {...this.initialValues},
													center: this.props.center,
													zoom: this.props.zoom,
													marker: null
												})
											}}
										/>
									</InputGroup>
								</Col>
							</Row>
						</FormGroup>
					}
					<LeafletMap
						style={{width: 100 + '%', height: this.props.height + 'px'}}
						center={this.state.center || this.props.center}
						zoom={this.state.zoom || this.props.zoom || 10}
					>
						<FeatureGroup ref={this._onFeatureGroupReady}>
							{ !this.props.disabled &&
								<EditControl
									ref={this.editControlRef}
									position="topright"
									draw={this.props.draw || {}}
									onEdited={this._onEdited}
									onCreated={this._onCreated}
									onDeleted={this._onDeleted}
								/>
							}
						</FeatureGroup>
						<TileLayer
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
						/>
						{this.props.addressSearch && this.state.marker}
					</LeafletMap>
				</Col>
			</Row>
		);
	}

	_editableFG = null;

	_onFeatureGroupReady = (reactFGref) => {

		// populate the leaflet FeatureGroup with the geoJson layers
		if (!reactFGref)
			return;
		let leafletGeoJSON = new L.GeoJSON(this.props.geojson);
		let leafletFG = reactFGref.leafletElement;

		leafletGeoJSON.eachLayer( (layer) => {
			leafletFG.addLayer(layer);
		});

		// store the ref for future access to content

		this._editableFG = reactFGref;
	}
}

PolygonInput.propTypes = {
	center: PropTypes.array.isRequired,
	height: PropTypes.number.isRequired,
	zoom: PropTypes.number,
	draw: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	polygon: PropTypes.array,
	disabled: PropTypes.bool,
};

export default PolygonInput;
