import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input } from 'reactstrap';

import { getData } from 'core/ducks/update';
import * as roles from 'core/model/roles';

class SelectDepartment extends Component {

	constructor(props) {
		super(props);
		this.state = {
			administrations: [],
			departments: [],
			selectedAdministration: '',
			selectedDepartment: '',
		};

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(
			getData('administration')
		).then((administrations) => {
			const administration = this.props.administration || (this.props.defaultValue || null);
			const selectedAdministration = administration ? administrations.filter(elem => elem.name === administration)[0].uuid : '';
			this.setState({administrations, selectedAdministration});
			this.props.onChange(selectedAdministration, '');
		}).catch((httpStatus) => {
			this.props.onChange('', '', httpStatus);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selectedAdministration !== this.state.selectedAdministration) {
			const { selectedAdministration } = this.state;
			if (selectedAdministration === '') {
				this.setState({
					departments: [],
					selectedDepartment: '',
				});
				return;
			}
		}
	}

	handleChange(event) {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		}, () => {
			const { selectedAdministration, selectedDepartment } = this.state;
			this.props.onChange(selectedAdministration, selectedDepartment);
		});
	}

	render() {

		const { administrations, selectedAdministration } = this.state;
		const { role } = this.props;

		return (
			<Row>
				{ role === roles.AUTHORIZED ?
					<Col className="text-right pt-0" sm="8"><u style={{fontSize: '150%'}}>Προς</u>:{' '}</Col>
					:
					<Col className="pt-0" sm="8"/>
				}
				<Col sm="4" className="py-0 mb-3">
					<Input
						disabled={(role === roles.EDITOR || role === roles.REVIEWER || this.props.defaultValue)}
						required
						type="select"
						className="mb-2"
						name="selectedAdministration"
						value={selectedAdministration}
						onChange={this.handleChange}
					>
						<option value="">--- Επιλέξτε Διεύθυνση ---</option>
						{ administrations.map(value => (
							<option key={`option_${value.uuid}`} value={value.uuid}>{value.name}</option>
						))}
					</Input>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	role: state.profile.user.role,
	administration: state.profile.user.administration,
	department: state.profile.user.department,
});

SelectDepartment = connect(mapStateToProps)(SelectDepartment);

export default SelectDepartment;
