import * as roles from 'core/model/roles';
import { StaticRoutes as CoreStaticRoutes } from 'core/model/routes';
import { applicationStatus } from './lib';

const Apply = '/submission';
const ApplyAdmin = '/admin/submission';
const ApplicationRoot = '/application';
const MyApplications = ApplicationRoot + '/list';

const ApplicationRootAdmin = '/admin/application';
let ApplicationList = ApplicationRootAdmin + '/list';
const PendingApplications = ApplicationRootAdmin + '/pending';

const Metadata = ApplicationRootAdmin + '/metadata';

const PdfTemplates = CoreStaticRoutes.Settings + '/pdf_templates';

export const StaticRoutes = {
	Apply,
	ApplyAdmin,
	ApplicationRoot,
	MyApplications,
	ApplicationRootAdmin,
	ApplicationList,
	PendingApplications,
	Metadata,
	PdfTemplates,
};

const Application = ApplicationRoot + '/:application([0-9a-f]{32})?';
const ApplicationAdmin = '/admin' + Application;
const ApplyWithParent = Apply + '/(parent)?/:parent([0-9a-f]{32})?';
const ApplyWithParentAdmin = '/admin' + ApplyWithParent;

const statusRegex = applicationStatus.join('|');
ApplicationList = ApplicationList + '/(status)?/:status(' + statusRegex + ')?/(administration)?/:administration([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?/(department)?/:department([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?';
const ApplicationReview = ApplicationRootAdmin + '/(review)/:application([0-9a-f]{32})?';

export const DynamicRoutes = {
	Application,
	ApplicationAdmin,
	ApplicationList,
	ApplicationReview,
	ApplyWithParent,
	ApplyWithParentAdmin
};

export const routes = [
	{
		url: Apply,
		name: 'submission',
		component: 'Apply',
		role: [roles.AUTHORIZED],
		exact: true,
	},
	{
		url: ApplyWithParent,
		name: 'submission',
		component: 'Apply',
		role: [roles.AUTHORIZED],
		exact: true,
	},	
	{
		url: ApplyWithParentAdmin,
		name: 'submission',
		component: 'Apply',
		role: roles.REVIEWER,
		exact: true,
	},
	{
		url: ApplyAdmin,
		name: 'submission',
		component: 'Apply',
		role: roles.REVIEWER,
		exact: true,
	},
	{
		url: Application,
		role: roles.REVIEWER,
		to: ApplicationAdmin,
		exact: true,
	},
	{
		url: Application,
		name: 'application',
		role: [roles.AUTHORIZED],
		component: 'Application',
		exact: true,
	},
	{
		url: ApplicationRootAdmin,
		name: 'applications',
		role: roles.REVIEWER,
		to: StaticRoutes.Metadata,
		exact: true,
	},
	{
		url: ApplicationAdmin,
		name: 'application',
		role: roles.REVIEWER,
		component: 'Application',
		exact: true,
	},
	{
		url: ApplicationRoot,
		name: 'application',
		role: [roles.AUTHORIZED],
		to: MyApplications,
		exact: true,
	},
	{
		url: MyApplications,
		name: 'list',
		role: [roles.AUTHORIZED],
		component: 'ApplicationList',
		exact: true,
	},
	{
		url: ApplicationList,
		name: 'list',
		role: roles.REVIEWER,
		component: 'ApplicationList',
		exact: true,
	},
	{
		url: PendingApplications,
		name: 'pending',
		role: [roles.REVIEWER, roles.EDITOR],
		component: 'ApplicationList',
		props: {onlyPending: true, withoutToolBox: true},
		exact: true
	},
	{
		url: ApplicationReview,
		name: 'review',
		role: roles.REVIEWER,
		component: 'Application',
		exact: true,
	},
	{
		url: Metadata,
		name: 'metadata',
		role: roles.REVIEWER,
		component: 'AttributeTable',
		exact: true
	},
	{
		url: PdfTemplates,
		name: 'pdf templates',
		role: roles.ADMIN,
		component: 'PdfTemplates',
	},
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
