import * as roles from 'core/model/roles';

export const applicationStatusStyle = {
	'progressing': {color: 'darkgoldenrod', badge: 'secondary'},
	'under_review': {color: 'blue', badge: 'primary'},
	'revised': {color: 'blueviolet', badge: 'warning'},
	'rejected': {color: 'red', badge: 'danger'},
	'accepted': {color: 'lightgreen', badge: 'info'},
	'completed': {color: 'green', badge: 'success'},
	'draft': {color: 'grey', badge: 'dark'}
}

export const applicationStatus = Object.keys(applicationStatusStyle);

export const metadata = {
	request: {
		type: 'textarea',
		label: 'Αίτημα',
		short: 'Αίτημα',
	},
	title: {
		type: 'textarea',
		label: 'Τίτλος Διαγράμματος',
		short: 'Τίτλος',
	},
	description: {
		type: 'text',
		label: 'Περιγραφή (έργο)',
		short: 'Περιγραφή',
	},
	scale: {
		type: 'select',
		label: 'Κλίμακα σύνταξης',
		short: 'Κλίμακα',
		options: {50: '1:50', 100: '1:100', 200: '1:200', 500: '1:500', 1000: '1:1000'},
		textForOther: 'Άλλο',
		step: 1,
		min: 0,
	},
	kind: {
		type: 'select',
		label: 'Είδος',
		short: 'Είδος',
	},
	date: {
		type: 'date',
		label: 'Ημερομηνία σύνταξης',
		short: 'Ημ/νία',
		maxDate: new Date(),
	},
	srs: {
		type: 'select',
		label: 'Σύστημα αναφοράς',
		short: 'ΣΑΣ',
		options: {'ΕΓΣΑ87': 'ΕΓΣΑ87', 'HATT': 'HATT', 'TM3': 'TM3', 'ΓΥΣ ED50': 'ΓΥΣ ED50', 'Αυθαίρετο / Τοπικό': 'Αυθαίρετο / Τοπικό', 'Δεν αναγράφεται': 'Δεν αναγράφεται'}
	},
	area: {
		type: 'text',
		label: 'Λεκτικός Γεωγραφικός Προσδιορισμός (π.χ. ενότητα/γειτονιά)',
		short: 'Περιοχή',
	},
	community: {
		type: 'select',
		label: 'Δημοτική Κοινότητα',
		short: 'Δ.Κ.',
	},
	ot: {
		type: 'text',
		label: 'Αριθμός Οικοδομικού Τετραγώνου',
		short: 'Ο.Τ.',
	},
	address: {
		type: 'address',
		label: 'Ταχ. Δ/νση',
		short: 'Διεύθυνση',
	},
	comment: {
		type: 'textarea',
		label: 'Σχόλια / παρατηρήσεις',
		short: 'Σχόλια',
	},
};

export const fileInputs = {
	dxf: {
		mimetype: 'application/dxf, application/x-autocad, application/x-dxf, drawing/x-dxf, image/vnd.dxf, image/x-autocad, image/x-dxf, zz-application/zz-winassoc-dxf',
		label: 'Αρχείο DXF',
		comment: 'αρχείο τύπου dxf συμβατό με τις προδιαγραφές του Δ. Αθηναίων',
		required: [roles.AUTHORIZED, roles.REVIEWER, roles.EDITOR]
	},
	pdf: {
		mimetype: 'application/pdf',
		label: 'Αρχείο PDF',
		comment: 'ψηφιακά υπογεγραμμένο διάγραμμα',
		required: [roles.AUTHORIZED, roles.REVIEWER, roles.EDITOR]
	},
	dwg: {
		mimetype: 'application/acad, application/x-acad, application/autocad_dwg, image/x-dwg, application/dwg, application/x-dwg, application/x-autocad, image/vnd.dwg, drawing/dwg',
		label: 'Αρχείο DWG',
		comment: 'αρχείο τύπου dwg',
		required: [roles.AUTHORIZED]
	},
	zip: {
		mimetype: 'application/x-compressed, application/x-zip-compressed, application/zip, multipart/x-zip',
		label: 'Αρχείο ZIP',
		comment: 'συνοδευτικά/υποστηρικτικά αρχεία (π.χ. φωτογραφίες, αποσπάσματα)',
		required: [],
	}
};

export const reviewData = {
	registration_number: {
		type: 'text',
		label: 'Αριθμός Πρωτοκόλλου',
	},
	corrections: {
		type: 'textarea',
		label: 'Σχόλια / Αιτούμενες Διορθώσεις',
		comment: 'Τα παραπάνω σχόλια θα είναι ορατά και στον υποβάλλοντα.',
	},
	comment: {
		type: 'textarea',
		label: 'Γενικά Σχόλια',
	},
	file: {
		type: 'file',
		label: 'Επισυναπτόμενο Αρχείο'
	}
};

const typeConvert = (type, shape) => {
	switch (type) {
		case 'LWPOLYLINE':
			return 'LineString';

		default:
			return 'Point';
	}
};

const vertices2coordinates = (vertices, shape=false) => {
	const coordinates = Object.values(vertices).map(vertex => {
		return [vertex.x, vertex.y];
	});
	if (shape)
		coordinates.push(coordinates[0]);

	return coordinates;
};

const position2coordinates = (position) => {
	return [position.x, position.y];
}

export const dxf2geojson = (layers, dxf) => {
	const { entities } = dxf;
	let geojson = {};
	let wrongTypes = [];
	entities.forEach(entity => {
		if (layers[entity.layer]) {
			const { vertices, position, layer, type, ...properties } = entity;
			const geom = type==='TEXT' ? entity.startPoint : (type==='POINT' ? position : vertices);

			if ((
				(Array.isArray(layers[layer].type) && layers[layer].type.includes(type)) || type === layers[layer].type
			) && (
				type !== 'LWPOLYLINE' || (layers[layer].closed!==false && layers[layer].closed!==true) || (layers[layer].closed === entity.shape)
			)) {
				if (!geojson[layer])
					geojson[layer] = {
						type: "FeatureCollection",
						name: layer,
						crs: { type: "name", properties: { name: "urn:ogc:def:crs:EPSG::2100" } },
						features: []
					};
				geojson[layer].features.push({
					type: "Feature",
					properties: {name: layers[layer].label, ...properties},
					geometry: {
						type: typeConvert(type, entity.shape),
						coordinates: type==='LWPOLYLINE'
							? vertices2coordinates(geom, entity.shape)
							: position2coordinates(geom),
					}
				});
			} else {
				wrongTypes.push({
					layer,
					correctType: layers[layer].closed
						? 'closed LWPOLYLINE'
						: Array.isArray(layers[layer].type) ? layers[layer].type.join(' ή ') : layers[layer].type,
					type: entity.shape ? `closed ${type}` : type
				});
			}
		}
	});

	if (wrongTypes.length === 0)
		wrongTypes = null;

	const report = Object.values(geojson).reduce((obj, layer) => ({
		...obj,
		[layer.name]: geojson[layer.name].features.length
	}), {});

	geojson = Object.values(geojson);

	return {geojson, report, wrongTypes};
}
