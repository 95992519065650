import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Modal, ModalHeader, ModalBody, Row, Col, Label
} from 'reactstrap';

import GeoJsonViewer from './geoJsonViewer';

class AdvancedView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: 0,
		};

		this.toggleTab = this.toggleTab.bind(this);
	}

	toggleTab(index) {
		this.setState({activeTab: index});
	}

	render() {

		const { isOpen, toggle, data } = this.props;

		if (!data)
			return null;

		return (
			<Modal isOpen={isOpen} className="modal-lg">
				<ModalHeader toggle={toggle}>{data['metadata'].title}</ModalHeader>
				<ModalBody>
					<Row>
						<Col md="6">
							<GeoJsonViewer geojson={data.info.geojson} />
						</Col>
						<Col md="6">
							<Row>
								<Col className="text-center p-0">
									<Label>Αρχεία:</Label>
									{ Object.keys(data.resources).map(key => (
										<a download href={data.resources[key].url} key={`resource_${key}`} className="mx-2">{key}</a>
									))}
								</Col>
							</Row>
							{ this.props.showUser &&
								<Row>
									<Col>
										<b>Συντάκτης:</b>
										{' ' + data['info'].author}
										<i
											className="fa fa-eye"
											role="button"
											onClick={this.props.showUser}
										/>
									</Col>
								</Row>
							}
							<p className="text-justify font-italic">
								{data.metadata.description}
							</p>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		);
	}
}

AdvancedView.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	showUser: PropTypes.func,
};

export default AdvancedView;
