import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row, Col, Form, FormGroup, FormText, Label, Input, Button, Modal, ModalBody, Progress,
} from 'reactstrap';

import Alert from 'core/views/modals/alert';
import { File } from 'input-fields';
import { applicationStatus, reviewData } from '../model/lib';
import { uploadData, cancelUpload } from '../ducks/upload';

class ApplicationReview extends Component {

	constructor(props) {
		super(props);
		this.initialValues = {
			corrections: '',
			status: '',
			comment: '',
			registration_number: '',
		};
		this.state = {
			values: {...this.initialValues},
			underSubmit: false,
			possibleTransitions: applicationStatus.slice(2, 5),
			files: [],
			showProgress: false,
			submitted: false,
		};

		this.handleFileChange = this.handleFileChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.submit = this.submit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleFileChange(event) {
		const { files } = event.target;
		this.setState({files});
	}

	handleSubmit(event) {
		event.preventDefault();
		this.setState({underSubmit: true});
	}

	submit() {
		const formData = new FormData();
		formData.append('_method', 'put');
		Object.keys(this.state.values).forEach(key => {
			formData.append(key, this.state.values[key]);
		});
		if (this.state.files[0])
			formData.append('pdf', this.state.files[0]);

		this.setState({showProgress: true}, () => {
			this.props.dispatch( uploadData(`application/token/${this.props.data.info.token}`, formData) )
				.then(response => {
					this.setState({showProgress: false, submitted: true});
				})
				.catch(error => {
					this.setState({showProgress: false});
					console.warn(error);
				});
		});
	}

	handleChange(event) {
		const { name, value } = event.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: value,
			}
		});
	}

	render() {

		const { values, underSubmit, possibleTransitions, submitted, showProgress } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<Form onSubmit={this.handleSubmit}>
				<FormGroup tag="fieldset" disabled={submitted}>
					<FormGroup className="float-right">
						<Label htmlFor="select_status" className="mr-2 font-weight-bold">Επισήμανση ως:</Label>
						<Input id="select_status" type="select" required value={values.status} name="status" onChange={this.handleChange} className="d-inline-block mr-2 w-auto">
							<option value="">Επιλέξτε...</option>
							{ possibleTransitions.map(status => (
								<option key={`option_${status}`} value={status}>{messages[status] || status}</option>
							))}
						</Input>
					</FormGroup>
					<div className="clearfix"/>
					<Row>
						<Col>
							{ Object.keys(reviewData).map(key => (
								<FormGroup key={`review_data_${key}`}>
									<Label className="application-label" htmlFor={`${key}_input`}>
										{reviewData[key].label}{(key === 'corrections' && values.status !== 'completed') ? ' *' : ''}
									</Label>
									<Input
										required={key==='corrections' && values.status!=='completed'}
										id={`${key}_input`}
										type={reviewData[key].type}
										value={values[key]}
										name={key}
										onChange={this.handleChange}
										rows={5}
									/>
									{ reviewData[key].comment &&
										<FormText>{reviewData[key].comment}</FormText>
									}
								</FormGroup>
							))}
						</Col>
					</Row>
					<Row>
						<Col>
							<File
								id="review_file_input"
								name="file"
								label="Επιλογή αρχείου"
								accept=".pdf"
								value={this.state.files}
								onChange={this.handleFileChange}
								multiple={false}
							/>
						</Col>
					</Row>
					<Row>
						<Col className="text-right">
							<Button className="mr-2">Υποβολή</Button>
						</Col>
					</Row>
				</FormGroup>
				{ underSubmit &&
					<Alert
						isOpen={underSubmit}
						toggle={() => this.setState({underSubmit: false})}
						title="confirm"
						message="review_will_be_definite"
						onConfirm={() => this.submit()}
					/>
				}
				<Modal isOpen={showProgress} className="modal-md">
					<ModalBody>
						<p>Παρακαλούμε περιμένετε τη μεταφόρτωση των δεδομένων...</p>
						<Progress animated value={this.props.progress} />
						<div className="text-right p-1">
							<Button type="button" onClick={() => {
								cancelUpload('Operation canceled by the user.');
								this.setState({showProgress: false});
							}}>
								Ακύρωση
							</Button>
						</div>
					</ModalBody>
				</Modal>
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
});

ApplicationReview = connect(mapStateToProps)(ApplicationReview);

export default ApplicationReview;
