import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Card, CardHeader, CardBody, ListGroup, ListGroupItem, ListGroupItemHeading,
	Button, Input, InputGroup, InputGroupAddon, Form
} from 'reactstrap';

import { getData, postData, deleteData } from 'core/ducks/update';
import { Loading } from 'core/components';
import { ErrorPage } from 'core/views/pages';
import EditCategory from '../modals/editCategory';
import Alert from 'core/views/modals/alert';
import { toggleModal } from 'core/ducks/ui/modal';

class EditCategories extends Component {

	constructor(props) {
		super(props);
		this.state = {
			values: [],
			pending: true,
			httpStatus: 200,
			newCategory: '',
			isModalOpen: false,
			activeId: null,
			activeCategory: ''
		};
		this.actions = bindActionCreators({toggleModal, deleteData}, props.dispatch);

		this.fetchData = this.fetchData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleAddCategory = this.handleAddCategory.bind(this);
		this.handleEditCategory = this.handleEditCategory.bind(this);
		this.handleDeleteCategory = this.handleDeleteCategory.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		this.props.dispatch(
			getData('planCategories')
		).then(response => {
			this.setState({
				values: [...response],
				pending: false,
				httpStatus: 200,
			});
		}).catch((httpStatus) => {
			this.setState({
				values: [],
				pending: false,
				httpStatus,
			})
		});
	}

	handleChange(event) {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	}

	handleAddCategory(event) {
		event.preventDefault();
		this.props.dispatch(
			postData('planCategories', {category: this.state.newCategory}, false)
		).then(() => {
			this.fetchData();
		}).catch((err) => {
			console.warn(err);
		});
	}

	handleEditCategory(activeId, activeCategory) {
		this.setState({
			isModalOpen: true,
			activeId,
			activeCategory,
		});
	}

	handleDeleteCategory(id) {
		this.actions.toggleModal(true,
			<Alert
				toggle={() => {this.actions.toggleModal()}}
				title="drop confirm"
				message="do you wish to continue"
				onConfirm={() => {
					this.actions.deleteData(`planCategories/id/${id}`, false)
						.then(() => {
							this.fetchData();
						}).catch((err) => {
							console.warn(err);
						});
				}}
			/>
		);
	}

	render() {

		const { pending, httpStatus, values, newCategory, isModalOpen, activeId, activeCategory } = this.state;

		if (pending)
			return (<Loading/>);
		if (httpStatus !== 200)
			return (<ErrorPage status={httpStatus}/>);

		return (
			<Card>
				<CardHeader>Κατηγορίες Διαγραμμάτων</CardHeader>
				<CardBody>
					<ListGroup>
						<ListGroupItem>
							<ListGroupItemHeading>Κατηγορίες</ListGroupItemHeading>
						</ListGroupItem>
						{ values.map(value => (
							<ListGroupItem key={`category_item_${value.id}`} action>
								{value.category}
								<i
									className="float-right fa fa-trash"
									role="button"
									title="Διαγραφή"
									onClick={() => {
										this.handleDeleteCategory(value.id);
									}}
								/>
								<i
									className="float-right fa fa-pencil"
									role="button"
									title="Επεξεργασία"
									onClick={() => {
										this.handleEditCategory(value.id, value.category)
									}}
								/>
							</ListGroupItem>
						))}
						<ListGroupItem>
							<Form onSubmit={this.handleAddCategory}>
								<InputGroup className="w-50">
									<Input value={newCategory} onChange={this.handleChange} name="newCategory" placeholder="Νέα Κατηγορία"/>
									<InputGroupAddon addonType="append">
										<Button title="Καταχώριση">
											<i className="fa fa-plus"/>
										</Button>
									</InputGroupAddon>
								</InputGroup>
							</Form>
						</ListGroupItem>
					</ListGroup>
				</CardBody>
				{ isModalOpen &&
					<EditCategory
						activeId={activeId}
						isOpen={isModalOpen}
						toggle={() => {
							this.setState({
								isModalOpen: false,
								activeId: null,
								activeCategory: '',
							});
						}}
						value={activeCategory}
						onSubmit={this.fetchData}
					/>
				}
			</Card>
		);

	}
}

const mapStateToProps = (state) => ({
	list: state.list,
});

EditCategories = connect(mapStateToProps)(EditCategories);

export default EditCategories;
