import React from 'react';
import ReactDOM from 'react-dom';
import * as L from 'leaflet';
import { MapControl, withLeaflet } from 'react-leaflet';
import proj4 from 'proj4';

import '../style/leaflet-info-control.scss';

proj4.defs("EPSG:2100", "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs");

const DumbControl = L.Control.extend({
	clicked: false,

	options: {
		className: "",
		onOff: "",
		handleOff: function noop() {}
	},

	onAdd(map) {
		const _controlDiv = L.DomUtil.create("div", this.options.className);
		const controlId = this.options.id;
		L.DomEvent.disableClickPropagation(_controlDiv);
		L.DomEvent.on(
			_controlDiv,
			'click',
			(ev) => {
				this.clicked = !this.clicked;
				const elems = document.getElementsByClassName('leaflet-interactive');
				if (this.clicked) {
					if (elems.length > 0)
						elems[0].style.display = 'none';
					L.DomUtil.addClass(map._container,'crosshair-cursor-enabled');
					document.getElementById(controlId).style.borderColor = 'green';
					map.on('click', (ev) => {
						this.options.onClick(ev, map);
						this.clicked = !this.clicked;
						L.DomUtil.removeClass(map._container,'crosshair-cursor-enabled');
						document.getElementById(controlId).style.borderColor = '#ccc';
						map.off('click');
						if (elems.length > 0)
							elems[0].style.display = 'inherit';
					});
				} else {
					L.DomUtil.removeClass(map._container,'crosshair-cursor-enabled');
					document.getElementById(controlId).style.borderColor = '#ccc';
					map.off('click');
					if (elems.length > 0)
						elems[0].style.display = 'inherit';
				}
			},
		);
		return _controlDiv;
	},

	onRemove(map) {
		if (this.options.onOff) {
			map.off(this.options.onOff, this.options.handleOff, this);
		}
		// L.DomEvent.off(document.getElementById(controlId), this.events);

		return this;
	}
});

class leafletInfoControl extends MapControl {

	createLeafletElement(props) {
		return new DumbControl(Object.assign({}, props));
	}

	componentDidMount() {
		super.componentDidMount();
		this.forceUpdate();
	}

	render() {
		if (!this.leafletElement || !this.leafletElement.getContainer()) {
			return null;
		}

		return ReactDOM.createPortal(
			<div id={this.props.id} className="leaflet-info-control" title={this.props.title}>
				<i className={this.props.icon}/>
			</div>,
			this.leafletElement.getContainer()
		);
	}
}

export default withLeaflet(leafletInfoControl);
