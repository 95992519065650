import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from 'reactstrap';

import T from 'modules/i18n';

class Alert extends Component {

	render() {

		const { onConfirm, isOpen, toggle, ...otherProps } = this.props;

		return (
			<Modal isOpen={isOpen} className="modal-md" {...otherProps}>
				<ModalHeader toggle={toggle}><T>{this.props.title}</T></ModalHeader>
				<ModalBody>
					<T>{this.props.message}</T>
				</ModalBody>
				<ModalFooter>
					<Button color="danger" onClick={() => {toggle(); onConfirm();}}>
						<T>yes</T>
					</Button>
					<Button color="success" onClick={toggle}>
						<T>no</T>
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

Alert.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string.isRequired,
	onConfirm: PropTypes.func.isRequired,
	isOpen: PropTypes.bool,
	toggle: PropTypes.func.isRequired,
};

Alert.defaultProps = {
	isOpen: true,
};

export default Alert;
