import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CookieBanner } from '@palmabit/react-cookie-law';

const defaultMessage = 'Ο ιστότοπος χρησιμοποιεί cookies απαραίτητα για την λειτουργία όπως περιγράφεται στην Πολιτική Προστασίας Προσωπικών Δεδομένων. Ενημερωθείτε για την Πολιτική Προστασίας Προσωπικών Δεδομένων (GDPR) του Δήμου Αθηναίων πατώντας στο παρακάτω σύνδεσμο. Με την αποδοχή, συναινείται στην χρήση των cookies.';

class GDPRSettingsPanel extends Component {

	render() {
		const messages = this.props.messages || {};

		return (
			<CookieBanner
				message={messages.mainMessage || defaultMessage}
				onAcceptStatistics={() => {document.cookie = "rcl_statistics_consent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"}}
				dismissOnScroll={false}
				showStatisticsOption={false}
				showMarketingOption={false}
				acceptButtonText={messages.accept || 'Accept'}
				necessaryOptionText={messages.necessary || 'Necessary'}
				preferencesOptionText={messages.preferences || 'Preferences'}
				privacyPolicyLinkText={messages.privacy_policy || 'Privacy Policy'}
				policyLink={this.props.policyLink || '/#'}
			/>
		);
	}
}

GDPRSettingsPanel.propTypes = {
	messages: PropTypes.object,
	policyLink: PropTypes.string,
};

export default GDPRSettingsPanel;
