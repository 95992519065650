import 'babel-polyfill'
import './react-leaflet-search.css';
import { Control, DomUtil, icon } from 'leaflet';
import React from 'react';
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom';
import * as L from 'leaflet';
import 'proj4leaflet';
import proj4 from 'proj4';
import { Popup, MapControl, Marker } from 'react-leaflet';
import InputBox from './InputBox';

proj4.defs("EPSG:2100", "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs");

export default class ReactLeafletSearch extends MapControl {
	constructor(props, context){
		super(props);
		this.div = DomUtil.create('div', 'leaflet-search-wrap');
		L.DomEvent.disableClickPropagation(this.div);
		L.DomEvent.disableScrollPropagation(this.div);
		this.state = {
			search: false,
			info: false,
			layer: null
		};
		this.markerIcon = icon({
			iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
			iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon-2x.png',
			shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [1, -34],
			tooltipAnchor: [16, -28],
			shadowSize: [41, 41]
		});
		this.SearchInfo = null; // searched lat,lng or response from provider
		this.map = context.map || props.leaflet.map;
	}

	createLeafletElement(props) {
		const ReactLeafletSearch = Control.extend({
		  onAdd: (map) => this.div,
		  onRemove: (map) =>  {}
		});
		return new ReactLeafletSearch(props);
	}

	latLngHandler(xy, info, raw, crs='EPSG:2100') {
		this.SearchInfo = {info, xy, raw};
		let latLng;
		if (crs !== 'EPSG:4326') {
			latLng = proj4(crs, 'EPSG:4326', xy);
			latLng = [latLng[1], latLng[0]];
		} else {
			latLng = [xy[1], xy[0]];
		}
		latLng.forEach((value, index) => {
			latLng[index] = value.toFixed(6);
		});
		const popUpStructure = (
			<div>
				<p>{ Array.isArray(info) ? info.toString() : info }</p>
				<div className='leaflet-search-popup'></div>
				<div>{`πλάτος: ${latLng[0]}`}</div>
				<div>{`μήκος: ${latLng[1]}`}</div>
			</div>
		)
		this.goToLatLng(latLng, popUpStructure);
	}

	otHandler(ot) {
		if (this.state.layer)
			this.map.removeLayer(this.state.layer);
		fetch(`/geoserver/administrative/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=administrative%3Aot&maxFeatures=50&outputFormat=application%2Fjson&cql_filter="ot_code" IN (${ot})`, {
			method: "GET",
			credentials: 'same-origin',
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			}
		}).then(response => {
			return response.json();
		}).then(response => {
			if (response.features.length === 0)
				return;
			let bounds = new L.Proj.geoJson(response).getBounds();
			this.map.flyToBounds(bounds, {maxZoom: 18});
		});
		const layer = L.tileLayer.wms(
			'/geoserver/wms/administrative',
			{
				layers: 'ot',
				transparent: true,
				format: 'image/png',
				cql_filter: `"ot_code" IN (${ot})`,
				maxZoom: 19,
			}
		).addTo(this.map);
		this.setState({layer});
	}

	removeMarkerHandler() {
		if (this.state.layer)
			this.map.removeLayer(this.state.layer);
		this.setState({
			search: false,
			layer: null,
		});
	}

	goToLatLng(latLng, info) {
		this.setState({ search: latLng, info: info }, () => {
			this.flyTo();
		});
	}
	flyTo() {
		if (this.props.mapStateModifier === 'flyTo') {
			this.map.flyTo([...this.state.search], this.props.zoom, this.props.zoomPanOptions);
		} else if (this.props.mapStateModifier === 'setView') {
			this.map.setView([...this.state.search], this.props.zoom, this.props.zoomPanOptions);
		} else {
			(typeof this.props.mapStateModifier === 'function') && this.props.mapStateModifier([...this.state.search]);
		}
	}

	componentDidMount() {
		super.componentDidMount();
		ReactDOM.render(<InputBox
			{...this.props}
			map={this.map}
			latLngHandler={this.latLngHandler.bind(this)}
			otHandler={this.otHandler.bind(this)}
			removeMarker={this.removeMarkerHandler.bind(this)} />, this.div);
	}

	componentDidUpdate() {
		this.markerRef && this.markerRef.leafletElement.openPopup();
	}

	defaultPopUp() {
	  return(
		<Popup>
			<span>{this.state.info}</span>
		</Popup>
	  );
	}

	render() {
		this.markerRef = false;
		return this.state.search && this.props.showMarker ? (
			<Marker
				ref={ref => (this.markerRef = ref)}
				icon={ this.props.markerIcon || this.markerIcon }
				key={`marker-search-${this.state.search.toString()}`}
				position={[...this.state.search]}>
				{ this.props.showPopup && (
					this.props.popUp ? this.props.popUp(this.SearchInfo) : this.defaultPopUp()
				)}
			</Marker>
		) : null;
	}
}

ReactLeafletSearch.propTypes = {
	position: PropTypes.string.isRequired,
	providerKey: PropTypes.string,
	inputPlaceholder: PropTypes.string,
	showMarker: PropTypes.bool,
	showPopup: PropTypes.bool,
	popUp: PropTypes.func,
	zoom: PropTypes.number,
	search: PropTypes.arrayOf(PropTypes.number),
	closeResultsOnClick: PropTypes.bool,
	openSearchOnLoad: PropTypes.bool,
	searchBounds: PropTypes.array,
	provider: PropTypes.string,
	providerOptions: PropTypes.object,
	zoomPanOptions: PropTypes.object,
	mapStateModifier: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string
	]),
	customProvider: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.func,
		PropTypes.object
	])
};

ReactLeafletSearch.defaultProps = {
	inputPlaceholder: "Search Lat,Lng",
	showMarker: true,
	showPopup: true,
	zoom: 10,
	search: [],
	closeResultsOnClick: false,
	openSearchOnLoad: false,
	searchBounds: [],
	provider: 'OpenStreetMap',
	providerOptions: {},
	mapStateModifier: 'flyTo',
	zoomPanOptions: {
		animate: true,
		duration: 0.25,
		easeLinearity: 0.25,
		noMoveStart: false
	},
	customProvider: false
};
