import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Row, Col, FormGroup, Card, CardText, CardBody, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';

import { search, resetSearch } from '../../ducks/search';
import { getData } from 'core/ducks/update';
import T from 'modules/i18n';
import { Pagination } from 'table';
import { AdvancedView as View } from 'app/components';
import { DynamicRoutes } from 'app/model/routes';
import { buildPath } from 'core/model/lib/urlTools';

class Search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: props.search || '',
			page: 1,
			isViewModalOpen: false,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.resetSearch = this.resetSearch.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.viewDetails = this.viewDetails.bind(this);
	}

	componentDidMount() {
		if (this.state.search.trim() !== '')
			this.props.dispatch( search((this.state.search.trim()), this.state.page) );
	}

	handleInputChange(event) {
		let target = event.target;
		this.setState({
			[target.name]: target.value
		});
	}

	resetSearch() {
		this.setState({
			search: ''
		});
		this.props.dispatch( resetSearch() );
	}

	handlePageChange(page) {
		this.setState({
			page,
		});
	}

	viewDetails(token) {
		this.props.dispatch( getData(`application/token/${token}`) ).then(() => {
			this.setState({isViewModalOpen: true});
		}).catch(err => {
			console.warn(err);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.search !== this.props.search)
			this.setState({search: this.props.search});
		if (prevState.search.trim() !== this.state.search.trim() || prevState.page !== this.state.page) {
			if (this.state.search.trim() === '') {
				this.props.dispatch( resetSearch() );
			} else {
				this.props.dispatch( search((this.state.search.trim()), this.state.page) );
			}
		}
	}

	render() {
		const { search, page } = this.state;
		const { results, count, results_per_page, total_pages } = this.props;

		return (
			<React.Fragment>
				<Row>
					<Col>
						<FormGroup className="mx-auto mt-5 mb-2 mw-786">
							<InputGroup>
								<input
									className="form-control rounded-left border-right-0 focus-no py-2 px-3"
									type="search"
									placeholder={this.props.i18n['type to search'] + '...'}
									name="search"
									value={search}
									onChange={this.handleInputChange}
									autoComplete="off"
									autoFocus
								/>
								<InputGroupAddon addonType="append">
									<InputGroupText className="bg-white rounded-right">
										{ search === '' ?
											<i className="fa fa-search text-primary"/>
											:
											<i className="fa fa-times" onClick={this.resetSearch}/>
										}
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
						</FormGroup>
					</Col>
				</Row>

				{ count >= 0 &&
					<Row>
						<small className="text-muted mx-5 my-2">
							{count > 0 &&
								<span>
									{results_per_page*(page - 1) + 1}{' - '}
									{Math.min(results_per_page*page,count)}{' '}<T>from</T>{' '}
								</span>
							}
							<span>
								{count}{' '}<T>results</T>
							</span>
						</small>
					</Row>
				}

				{ results.map(result =>
					<Row key={`result_${result.application}`}>
						<Card className="mx-5 rounded shadow-sm">
							<CardBody>
								<CardText
									className={this.props.refreshing ? 'semi-transparent text-dark search-results' : 'text-dark search-results'}
									dangerouslySetInnerHTML={{__html: result.headline + '...'}}
								/>
								<CardText className="text-muted">
									<span className="mr-2" role="link" onClick={() => {this.viewDetails(result.application)}}>
										<T>view</T>
									</span>
									<Link to={buildPath(DynamicRoutes.Application, [result.application])} role="link">
										<T>details</T>
									</Link>
								</CardText>
							</CardBody>
						</Card>
					</Row>
				)}

				{ total_pages > 0 &&
					<Row>
						<Pagination
							className="mx-auto"
							page={this.state.page}
							total={total_pages}
							onClick={(page) => {
								if (page !== this.state.page)
									this.handlePageChange(page)}
							}
						/>
					</Row>
				}
				{ this.state.isViewModalOpen &&
					<View
						toggle={() => {
							this.setState({isViewModalOpen: !this.state.isViewModalOpen});
						}}
						isOpen={this.state.isViewModalOpen}
						data={this.props.view}
					/>
				}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	search: state.search.query,
	results: state.search.results,
	count: state.search.count,
	results_per_page: state.search.results_per_page,
	page: state.search.page,
	total_pages: state.search.total_pages,
	refreshing: state.search.refreshing,
	view: state.update.response,
	http_status: state.update.status,
	i18n: state.i18n.messages,
});

Search = connect(mapStateToProps)(Search);

export default Search;
