import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
	Row, Col, Form, FormGroup, FormFeedback, InputGroup, InputGroupAddon, InputGroupText, FormText,
} from 'reactstrap';

import { Input } from 'core/components';
import { getValidation, validate } from 'core/ducks/forms';
import { pushNotification } from 'core/ducks/notifications';
import { Loading } from 'core/components';
import T from 'modules/i18n';
import { Address } from 'input-fields';

class Register extends Component {

	constructor(props) {
		super(props);
		const prefilled = props.prefilled ? props.prefilled : {};
		this.empty_state = {
			username: prefilled.username || '',
			firstname: prefilled.firstname || '',
			lastname: prefilled.lastname || '',
			email: prefilled.email || '',
			password: '',
			address: prefilled.address || '',
			phone: prefilled.phone || '',
		}
		this.state = {
			values: {
				...this.empty_state,
				password_repeat: ''
			},
			under_submit: false,
			submitted: false,
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.submit = this.submit.bind(this);
	}

	handleChange(event) {
		const { name, type, value } = event.target
		this.setState({
			values: {
				...this.state.values,
				[name]: type === 'checkbox' ? event.target.checked : value
			},
			under_submit: false
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		if (this.state.values.password !== this.state.values.password_repeat) {
			dispatch(pushNotification({
				body: "passwords do not match",
				type: "warning",
				duration: 2
			}));
		} else {
			let data = Object.assign({}, this.state.values);
			delete data.password_repeat;
			this.setState({under_submit: true}, () => dispatch(validate(data, rules, 'users')));
		}
	}

	submit(data) {
		this.props.onSubmit(data);
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(getValidation('users'));
	}

	componentDidUpdate(prevProps) {
		if (this.state.under_submit && this.props.valid) {
			let data = Object.assign({}, this.state.values);
			delete data.password_repeat;
			this.submit(data);
			this.setState({under_submit: false});
		}
	}

	render() {
		if (this.props.pending || this.props.validation_pending || this.props.validation_scope !== 'users')
			return <Loading />

		const { rules, instructions, validationMsg } = this.props;
		const { values } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<Form onSubmit={this.handleSubmit}>
				<fieldset disabled={this.props.submitted}>
					<Row>
						<Col md="6" className="pb-0">
							<FormGroup tag="fieldset">
								<legend className="px-3 py-2 application-legend">
									<b><T>login details</T></b>
								</legend>
								{ (instructions && instructions.username) && <FormText><T>{instructions.username}</T></FormText>}
								<InputGroup className="mb-3">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="icon-user"></i>
										</InputGroupText>
									</InputGroupAddon>
									<Input
										type="text"
										placeholder={messages.username || 'username'}
										name="username"
										autoComplete="username"
										onChange={this.handleChange}
										minLength={rules.username.min_size}
										maxLength={rules.username.max_size}
										pattern={rules.username.validation}
										value={values.username}
										valid={validationMsg.username === ''}
										required
									/>
									<InputGroupAddon addonType="append">*</InputGroupAddon>
									<FormFeedback>{validationMsg.username || rules.username.message}</FormFeedback>
								</InputGroup>
								{ (instructions && instructions.password) && <FormText><T>{instructions.password}</T></FormText>}
								<InputGroup className="mb-3">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="icon-lock"></i>
										</InputGroupText>
									</InputGroupAddon>
									<Input
										type="password"
										placeholder={messages.password || 'password'}
										name="password"
										autoComplete="new-password"
										onChange={this.handleChange}
										minLength={rules.password.min_size}
										maxLength={rules.password.max_size}
										pattern={rules.password.validation}
										value={values.password}
										valid={validationMsg.password === ''}
										required
									/>
									<InputGroupAddon addonType="append">*</InputGroupAddon>
									<FormFeedback>{validationMsg.password || rules.password.message}</FormFeedback>
								</InputGroup>
								<InputGroup className="mb-4">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="fa fa-repeat"></i>
										</InputGroupText>
									</InputGroupAddon>
									<Input
										type="password"
										placeholder={messages['repeat password'] || 'repeat password'}
										name="password_repeat"
										autoComplete="new-password"
										onChange={this.handleChange}
										value={values.password_repeat}
										required
									/>
									<InputGroupAddon addonType="append">*</InputGroupAddon>
								</InputGroup>
							</FormGroup>
						</Col>
						<Col md="6" className="pb-0">
							<FormGroup tag="fieldset">
								<legend className="px-3 py-2 application-legend">
									<b><T>personal details</T></b>
								</legend>
								{ (instructions && instructions.firstname) && <FormText><T>{instructions.firstname}</T></FormText>}
								<InputGroup className="mb-3">
									<Input
										type="text"
										placeholder={messages['given name'] || 'given name'}
										name="firstname"
										autoComplete="given-name"
										onChange={this.handleChange}
										minLength={rules.firstname.min_size}
										maxLength={rules.firstname.max_size}
										pattern={rules.firstname.validation}
										value={values.firstname}
										valid={validationMsg.firstname === ''}
										required
									/>
									<InputGroupAddon addonType="append">*</InputGroupAddon>
									<FormFeedback>{validationMsg.firstname || rules.firstname.message}</FormFeedback>
								</InputGroup>
								{ (instructions && instructions.lastname) && <FormText><T>{instructions.lastname}</T></FormText>}
								<InputGroup className="mb-3">
									<Input
										type="text"
										placeholder={messages['family name'] || 'family name'}
										name="lastname"
										autoComplete="family-name"
										onChange={this.handleChange}
										minLength={rules.lastname.min_size}
										maxLength={rules.lastname.max_size}
										pattern={rules.lastname.validation}
										value={values.lastname}
										valid={validationMsg.lastname === ''}
										required
									/>
									<InputGroupAddon addonType="append">*</InputGroupAddon>
									<FormFeedback>{validationMsg.lastname || rules.lastname.message}</FormFeedback>
								</InputGroup>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className="py-0">
							{ (instructions && instructions.email) && <FormText><T>{instructions.email}</T></FormText>}
							<InputGroup className="mb-3">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>@</InputGroupText>
								</InputGroupAddon>
								<Input
									type="text"
									placeholder="Email"
									name="email"
									onChange={this.handleChange}
									minLength={rules.email.min_size}
									maxLength={rules.email.max_size}
									pattern={rules.email.validation}
									value={values.email}
									autoComplete="email"
									valid={validationMsg.email === ''}
									required
								/>
								<InputGroupAddon addonType="append">*</InputGroupAddon>
								<FormFeedback>{validationMsg.email || rules.email.message}</FormFeedback>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup tag="fieldset">
								<legend className="px-3 py-2 application-legend">
									<b><T>contact details</T></b>
								</legend>
								<Address
									name="address"
									value={{address: '', location: {}}}
									onChange={this.handleChange}
									locale='el'
									showCoordinates={false}
									center={this.props.mapSettings.center}
									zoom={14}
								/>
								{ (instructions && instructions.phone) && <FormText><T>{instructions.phone}</T></FormText>}
								<InputGroup className="mb-3">
									<InputGroupAddon addonType="prepend">
										<InputGroupText><i className="fa fa-phone"/></InputGroupText>
									</InputGroupAddon>
									<Input
										type="text"
										placeholder={messages.phone || 'phone'}
										name="phone"
										minLength={rules.phone.min_size}
										maxLength={rules.phone.max_size}
										pattern={rules.phone.validation}
										onChange={this.handleChange}
										value={values.phone}
										autoComplete="tel"
										valid={validationMsg.phone === ''}
									/>
									<FormFeedback>{validationMsg.phone || rules.phone.message}</FormFeedback>
								</InputGroup>
							</FormGroup>
						</Col>
					</Row>
				</fieldset>
				<Row>
					<Col className="m-0 py-0 pr-3 text-muted text-right">
						*<T>required fields</T>.
					</Col>
				</Row>
				{this.props.children}
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	pending: state.forms.pending,
	rules: state.forms.validation.rules,
	validation_pending: state.forms.validation.pending,
	validation_scope: state.forms.validation.scope,
	validationMsg: state.forms.validation_msgs,
	valid: state.forms.valid,
	notifications: state.notifications.messages,
	mapSettings: state.ui.settings.values.map,
});

Register = connect(mapStateToProps)(Register);

export default Register;
