import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, CardText } from 'reactstrap';

import T from 'modules/i18n';
import { StaticRoutes, DynamicRoutes } from '../model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import { SecureContent, Dashboard as Template } from 'core/components';
import * as roles from 'core/model/roles';
import { getData } from 'core/ducks/update';

class Dashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: {},
			ready: false,
		};
	}

	componentDidMount() {
		this.props.dispatch(getData('application/admin/stats'))
			.then((data) => this.setState({data, ready: true}));
	}

	render() {
		const { role } = this.props;
		const { data, ready } = this.state;
		if (role !== roles.GUEST && !ready)
			return null;

		let appCols = [];
		if ( role !== roles.GUEST ) {
			let keys = Object.keys(data.status);
			keys.forEach((status, index) => {
				let number = data.status[status];
				let colIndex = Math.floor(index/Math.ceil(keys.length/2));
				if (!appCols[colIndex])
					appCols[colIndex] = [];
				appCols[colIndex].push(
					<Link key={`link_${status}`} to={buildPath(DynamicRoutes.ApplicationList, {status})}>
						<CardText className="text-light text-lowercase"><big>{number}</big> <T>{status}</T></CardText>
					</Link>
				);
			});
		}

		return (
			<Template>
				<SecureContent role={roles.REVIEWER}>
					<Card>
						<CardHeader>
							<Link to={StaticRoutes.Metadata} className="no-decoration">
								<i className="icon-book-open" /> { data.total } <T>applications</T>
							</Link>
						</CardHeader>
						<CardBody className="text-white bg-info">
							<Row>
								<Col>{appCols[0]}</Col>
								<Col>{appCols[1]}</Col>
							</Row>
						</CardBody>
					</Card>
				</SecureContent>
			</Template>
		);
	}
}

const mapStateToProps = (state) => ({
	role: state.profile.user.role,
});

Dashboard = connect(mapStateToProps)(Dashboard);

export default Dashboard;
