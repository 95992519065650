import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Card, CardHeader, CardBody, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading,
	Button, Input, InputGroup, InputGroupAddon, Form
} from 'reactstrap';

import { requestData } from 'core/ducks/list';
import { deleteData, postData, updateData } from 'core/ducks/update';
import { Loading } from 'core/components';
import EditAdministration from '../modals/editAdministration';
import Alert from 'core/views/modals/alert';
import { toggleModal } from 'core/ducks/ui/modal';

class EditDepartments extends Component {

	constructor(props) {
		super(props);
		this.state = {
			values: [],
			pending: true,
			administration: '',
			departments: [],
			openModal: false,
			newDepartment: '',
			departmentUnderEdit: {uuid: null, name: null},
		};
		this.actions = bindActionCreators({toggleModal, deleteData, requestData}, props.dispatch);

		this.fetchData = this.fetchData.bind(this);
		this.handleAdministrationSelect = this.handleAdministrationSelect.bind(this);
		this.handleEditAdministration = this.handleEditAdministration.bind(this);
		this.handleDeleteAdministration = this.handleDeleteAdministration.bind(this);
		this.getModalResponse = this.getModalResponse.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleAddDepartment = this.handleAddDepartment.bind(this);
		this.handleDepartmentDelete = this.handleDepartmentDelete.bind(this);
		this.handleDepartmentEdit = this.handleDepartmentEdit.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			(prevProps.list.administrations && prevProps.list.administrations.pending && !this.props.list.administrations.pending) ||
			(prevProps.list.administrations && prevProps.list.administrations.refreshing && !this.props.list.administrations.refreshing)
		) {
			this.setState({
				values: [...this.props.list.administrations.data],
				pending: false
			});
		}
		if (
			(prevProps.list.departments && prevProps.list.departments.pending && !this.props.list.departments.pending) ||
			(prevProps.list.departments && prevProps.list.departments.refreshing && !this.props.list.departments.refreshing)
		) {
			this.setState({
				departments: [...this.props.list.departments.data],
				refreshing: false,
			});
		}
	}

	handleChange(event) {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	}

	fetchData() {
		return this.props.dispatch(
			requestData('administrations', 'administration')
		);
	}

	handleAdministrationSelect(event) {
		const administration = event.target.value
		if (administration === '') {
			this.setState({administration, departments: []});
			return;
		}
		this.setState({
			administration,
			refreshing: true,
		});
		this.props.dispatch(
			requestData('departments', `department/administration/${administration}`)
		);
	}

	handleEditAdministration(administration=false) {
		const { values } = this.state;
		this.setState({
			openModal: administration ? values.filter(value => value.uuid === administration)[0] : true
		});
	}

	handleDeleteAdministration(uuid) {
		this.actions.toggleModal(true,
			<Alert
				toggle={() => {this.actions.toggleModal()}}
				title="drop confirm"
				message="administration deletion confirm"
				onConfirm={() => {
					this.actions.deleteData(`administration/uuid/${uuid}`, false);
					this.fetchData();
					this.setState({
						administration: '',
					});
				}}
			/>
		);
	}

	getModalResponse(uuid) {
		this.fetchData().then(() => {
			if (uuid)
				this.handleAdministrationSelect({target: {value: uuid}});
		});
	}

	handleAddDepartment(event) {
		event.preventDefault();
		const { administration, newDepartment } = this.state;
		this.props.dispatch(
			postData('department', {administration, name: newDepartment}, false)
		).then(() => {
			this.props.dispatch(
				requestData('departments', `department/administration/${administration}`)
			);
			this.setState({newDepartment: ''});
		}).catch((err) => {
			console.warn(err);
		});
	}

	handleDepartmentDelete(uuid) {
		this.actions.toggleModal(true,
			<Alert
				toggle={() => {this.actions.toggleModal()}}
				title="drop confirm"
				message="do you wish to continue"
				onConfirm={() => {
					this.actions.deleteData(`department/uuid/${uuid}`, false)
						.then(() => {
							this.actions.requestData('departments', `department/administration/${this.state.administration}`)
						}).catch((err) => {
							console.warn(err);
						});
				}}
			/>
		);
	}

	openDepartmentEdit(value) {
		this.setState({
			departmentUnderEdit: value,
		});
	}

	handleDepartmentEdit(event) {
		event.preventDefault();
		const { uuid, name } = this.state.departmentUnderEdit;
		this.props.dispatch( updateData(`department/uuid/${uuid}`, {name}, false) )
			.then(() => {
				this.actions.requestData('departments', `department/administration/${this.state.administration}`)
				this.setState({departmentUnderEdit: {uuid: null, name: null}});
			}).catch(err => {
				console.warn(err);
			});
	}

	render() {

		if (this.state.pending)
			return (<Loading/>);

		const { values, administration, departments, openModal, newDepartment } = this.state;

		return (
			<Card>
				<CardHeader>Διευθύνσεις / Τμήματα</CardHeader>
				<CardBody>
					<Row>
						<Col xs="0" sm="6" md="8"/>
						<Col xs="12" sm="6" md="4">
							<Input type="select" value={administration} onChange={this.handleAdministrationSelect}>
								<option value="">---Επιλέξτε Διεύθυνση---</option>
								{ values.map((value, index) => (
									<option key={`administration_option_${index}`} value={value.uuid}>{value.name}</option>
								))}
							</Input>
						</Col>
					</Row>
					{ administration !== '' &&
						<Row>
							<Col>
								<ListGroup>
									<ListGroupItem>
										<ListGroupItemHeading><b>Τμήματα</b></ListGroupItemHeading>
									</ListGroupItem>
									{ departments.map(value => (
										<ListGroupItem key={`department_${value.uuid}`} action>
											{ this.state.departmentUnderEdit.uuid === value.uuid ?
												<Form onSubmit={this.handleDepartmentEdit}>
													<Input
														value={this.state.departmentUnderEdit.name}
														onKeyDown={(e) => {
															if (e.keyCode === 27)
																this.setState({departmentUnderEdit: {uuid: null, name: null}});
														}}
														onChange={(e) => {
															this.setState({departmentUnderEdit: {
																...this.state.departmentUnderEdit,
																name: e.target.value
															}})
														}}
													/>
												</Form>
												:
												<>
													{value.name}
													<i role="button" className="float-right fa fa-edit" onClick={() => {this.openDepartmentEdit(value)}}/>
												</>
											}
										</ListGroupItem>
									))}
									<ListGroupItem>
										<Form onSubmit={this.handleAddDepartment}>
											<InputGroup className="w-50">
												<Input value={newDepartment} onChange={this.handleChange} name="newDepartment" placeholder="Νέο τμήμα"/>
												<InputGroupAddon addonType="append">
													<Button title="Καταχώριση">
														<i className="fa fa-plus"/>
													</Button>
												</InputGroupAddon>
											</InputGroup>
										</Form>
									</ListGroupItem>
								</ListGroup>
							</Col>
						</Row>
					}
					<Row>
						<Col className="text-center">
							<Button className="mr-2" color="success" type="button" onClick={() => {this.handleEditAdministration(false)}}>Νέα Διεύθυνση</Button>

							<Button
								disabled={administration === ''}
								className="mr-2"
								color="primary"
								type="button"
								onClick={() => {this.handleEditAdministration(administration)}}
							>
								Επεξεργασία Τίτλου
							</Button>

							<Button
								disabled={administration === ''}
								className="mr-2"
								color="danger"
								type="button"
								onClick={() => {
									this.handleDeleteAdministration(administration);
								}}
							>
								Διαγραφή Διεύθυνσης
							</Button>
						</Col>
					</Row>
				</CardBody>
				{ openModal &&
					<EditAdministration
						uuid={openModal.uuid}
						value={openModal.name}
						isOpen={openModal===false ? false : true}
						toggle={() => {
							this.setState({openModal: openModal===false ? true : false})
						}}
						onSubmit={this.getModalResponse}
					/>
				}
			</Card>
		);
	}

}

const mapStateToProps = (state) => ({
	list: state.list
});

EditDepartments = connect(mapStateToProps)(EditDepartments);

export default EditDepartments;
