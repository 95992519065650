const getLocale = () => (dispatch, getState) => {
	let locale = '';
	const { settings } = getState().ui;
	if (!settings.values.multilingual) {
		if (settings.values.locales) {
			locale = settings.values.locales[0];
		}
	} else {
		locale = getState().i18n.locale;
	}

	return locale;
};

export default getLocale;
