import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputGroupAddon, InputGroupText, FormFeedback } from 'reactstrap';

class File extends Component {

	constructor(props) {
		super(props);
		this.state = {
			files: null,
			value: '',
		};

		this.fileInputRef = React.createRef();

		this.onChange = this.onChange.bind(this);
		this.handleBrowserClick = this.handleBrowserClick.bind(this);
	}

	onChange(event) {
		let input = event.target;
		let { onChange } = this.props;
		let files = this.getSelectedFiles(input);

		if (typeof onChange === 'function')
			onChange(event);

		this.setState({files});
	}

	getSelectedFiles(input) {
		let files = Object.values(input.files).map(file => file.name);
		return files.join(', ');
	}

	handleBrowserClick(event) {
		this.fileInputRef.current.click();
	}

	render() {
		const { className, label, onChange, children, innerRef, accept, buttonText, value, valid, feedback, ...attributes } = this.props;

		const { files } = this.state;

		return (
			<div>
				<input className="d-none" type="file" accept={accept} {...attributes} ref={this.fileInputRef} onChange={this.onChange} />
				<InputGroup>
					<Input
						onChange={() => {}}
						value={files || label || 'Choose file'}
						onClick={this.handleBrowserClick}
						valid={valid}
						invalid={valid===false ? true : undefined}
					/>
					<InputGroupAddon addonType="append" onClick={this.handleBrowserClick}>
						<InputGroupText>{buttonText || 'Browse'}</InputGroupText>
					</InputGroupAddon>
					{ feedback &&
						<FormFeedback>{feedback}</FormFeedback>
					}
				</InputGroup>
			</div>
		);
	}
}

File.propTypes = {
	className: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	label: PropTypes.node,
	onChange: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.func]),
	innerRef: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.func,
	]),
	buttonText: PropTypes.string,
	valid: PropTypes.bool,
	feedback: PropTypes.string,
};

export default File;