import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import * as roles from 'core/model/roles';
import {
	Filter, FilterGroup, Table, Title, Button, Tbody, Thead, Toolbox, Tr, Search, Pagination
} from 'table';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { Loading, SecureContent } from 'core/components';
import { ErrorPage } from 'core/views/pages';
import { ExportToCSV } from 'core/model/lib';
import { applicationStatus, applicationStatusStyle } from '../../model/lib';
import { getData as getElement } from 'core/ducks/update';
import { AdvancedView as View } from '../../components';
import { AssignModal } from '../modals';
import T from 'modules/i18n';

class ApplicationList extends Component {

	constructor(props) {
		super(props);
		this.initialState = {
			query: '',
			status: '',
			sort: 'created',
			sort_method: 'desc',
			httpStatus: 200,
			selectedAdministration: '',
			selectedDepartment: '',
		};
		const { params } = props.match;
		const initialize = {
			status: (params && params.status && params.status!=='administration' && params.status!=='department') ? params.status : '',
			selectedAdministration: (params && params.administration && params.administration!=='department') ? params.administration : '',
			selectedDepartment: (params && params.department) ? params.department : '',
		};
		this.state = {...this.initialState, ...initialize, page: 1, refreshing: false, mounted: true, isViewOpen: false, administrations: [], departments: [], assign: null};

		this.fields = props.profile.role===roles.AUTHORIZED
				? ['address', 'created', 'status', 'administration', 'department']
				: ['address', 'created', 'modified', 'reviewer', 'status',  'administration', 'department'];

		this.layout = {
			address: {sortable: true},
			created: {type: 'date', sortable: true},
			status: {type: 'translatable'},
			administration: {sortable: true, type: 'text'},
			department: {sortable: true, type: 'text'},
		};
		if (props.profile.role!==roles.AUTHORIZED) {
			this.layout.modified = {type: 'date', sortable: true};
			this.layout.reviewer = {sortable: true};
		}

		this.badge_colors = Object.keys(applicationStatusStyle).reduce((obj, key) => ({
			...obj,
			[key]: applicationStatusStyle[key].badge,
		}), {});

		this.fetchData = this.fetchData.bind(this);
		this.createUrl = this.createUrl.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
	}

	componentDidMount() {
		if (this.props.profile.role !== roles.AUTHORIZED) {
			const { dispatch } = this.props;
			dispatch( getElement('administration') ).then(administrations => {
				this.setState({administrations});
				this.fetchData();
			}).catch(httpStatus => {
				this.setState({httpStatus});
			});
			dispatch( getElement('department') ).then(departments => {
				this.setState({departments});
			}).catch(httpStatus => {
				this.setState({httpStatus});
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { params } = this.props.match;
		if (
			params.status !== prevProps.match.params.status ||
			params.administration !== prevProps.match.params.administration ||
			params.department !== prevProps.match.params.department ||
			prevState.query !== this.state.query
		) {
			let state = {page: 1};
			if (prevState.query !== this.state.query)
				state = Object.assign(state, {query: this.state.query});
			if (params.status !== prevProps.match.params.status && params.status !== 'administration' && params.status !== 'department')
				state = Object.assign(state, {status: params.status})
			if (params.administration !== prevProps.match.params.administration && params.administration !== 'department')
				state = Object.assign(state, {selectedAdministration: params.administration});
			if (params.department !== prevProps.match.params.department)
				state = Object.assign(state, {selectedDepartment: params.department});
			this.setState(state, this.fetchData);
		}
	}

	fetchData() {
		const url = this.createUrl();
		this.props.dispatch(
			requestData('application', url)
		).catch(httpStatus => {
			this.setState({httpStatus});
		});
	}

	findAdministration = (administration) => {
		if (!administration)
			return null;
		const result = this.state.administrations.filter(elem => elem.name === administration);
		if (result.length === 0)
			return null;
		return result[0].uuid
	}

	createUrl(scope='') {
		let { query, sort, sort_method } = this.state;
		let url = `application/page/${this.state.page}/sort/${sort}/sort_method/${sort_method}`;
		query = (query !== '') ? '/query/' + query : '';
		const filters = {status: this.state.status, administration: this.state.selectedAdministration, department: this.state.selectedDepartment};
		let fq = [];
		['status', 'administration', 'department'].forEach(filter => {
			if (filters[filter] && filters[filter] !== '')
				fq.push(`${filter}:${filters[filter]}`);
		});
		if (this.props.onlyPending) {
			fq.push('status:under_review');
			if (this.props.user.role === roles.REVIEWER) {
				fq.push(`reviewer:${this.props.user.uuid}`);
			} else {
				fq.push('status:progressing');
				const administration = this.findAdministration(this.props.user.administration);
				if (administration)
					fq.push(`administration:${administration}`)
			}
		}
		if (fq.length > 0) {
			fq = fq.join(';');
			fq = `/fq/${fq}`;
		} else {
			fq = '';
		}
		url += query + fq;
		if (scope === 'csv')
			url += '/limit/100000';

		return url;
	}

	handlePageChange(page) {
		this.setState({ page }, this.fetchData);
	}

	handleSelect(token) {
		const url = this.props.onlyPending ? DynamicRoutes.ApplicationReview : DynamicRoutes.Application;
		const path = buildPath(url, [token]);
		this.props.history.push(path);
	}

	handleFilterChange(event) {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: 1
		});

		if (this.props.match && this.props.match.params) {
			let { status, administration, department } = this.props.match.params;
			status = name==='status' ? (value || undefined) : status;
			administration = name==='selectedAdministration' ? (value || undefined) : administration;
			department = name==='selectedDepartment' ? (value || undefined) : department;
			const url = buildPath(DynamicRoutes.ApplicationList, {status, administration, department});
			this.props.history.push(url);
		}
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState,
			page: 1
		}, this.fetchData);
		this.props.history.push(StaticRoutes.ApplicationList);
	}

	handleCSVExport() {
		const { messages } = this.props.i18n || {messages: {}};
		const url = this.createUrl('csv');
		this.props.dispatch(requestData('csv', url)).then(() => {
			let data = Object.keys(this.props.list.csv.data.values).map((index) => {
				let row = this.props.list.csv.data.values[index];
				return this.fields.map((col) => {
					return (this.layout[col] && this.layout[col].type === 'translatable' && messages[row[col]]) ? messages[row[col]] : row[col];
				});
			});
			let fields = this.fields.map((field) => {
				return messages[field] ? messages[field] : field;
			});
			let csv = new ExportToCSV('submits.csv', fields, data);
			csv.createLink();
		});
	}

	handleSortChange(sort) {
		if (sort===this.state.sort) {
			this.setState({ sort_method: this.state.sort_method==='asc' ? 'desc' : 'asc'}, this.fetchData);
		} else {
			this.setState({ sort }, this.fetchData);
		}
	}

	viewDetails(token) {
		const url = `application/token/${token}`;
		this.props.dispatch( getElement(url) ).then(() => {
			this.setState({isViewOpen: true});
		}).catch((err) => {
			console.warn(err);
		});
	}

	render() {

		const { data, info, list, onlyPending, withoutToolBox } = this.props;
		if (this.state.httpStatus !== 200)
			return (<ErrorPage status={this.state.httpStatus}/>);
		if (this.props.pending || !data)
			return (<Loading />);
		if (list.application.status !== 200 && list.application.status !== '')
			return (<ErrorPage status={list.application.status} />);

		const { messages } = this.props.i18n || {messages: {}};
		const { administrations, departments } = this.state;

		return (
			<Row>
				<Col>
					<Table>
						<Title>
							<T>{onlyPending ? 'pending' : 'applications'}</T>
							{ !withoutToolBox &&
								<SecureContent role={roles.REVIEWER}>
									<Button type="toolbox" title="filters" className="float-right"/>
								</SecureContent>
							}
						</Title>
						<SecureContent role={roles.REVIEWER}>
							<Toolbox onReset={this.handleToolboxReset}>
								<Row>
									<Col xs="12" lg="8" className="form-group text-right">
										<Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleFilterChange} name="query" />
										<Button type="csv" title={`${messages.export || 'export'} csv`} onClick={this.handleCSVExport}>
											<T>export</T> csv
										</Button>
										<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
									</Col>
									<Col xs="12" lg="4">
										<FilterGroup>
											{ !onlyPending &&
												<>
													<Filter onChange={this.handleFilterChange} name="status" defaultValue={this.state.status} >
														<option value="">{`${messages.choose || 'choose'} ${messages.status || 'status'}`}</option>
														{ applicationStatus.map((status) => (
															<option key={`option_${status}`} value={status}>{messages[status] || 'status'}</option>
														))}
													</Filter>
													<Filter onChange={this.handleFilterChange} name="selectedAdministration" defaultValue={this.state.selectedAdministration} >
														<option value="">{`${messages.choose || 'choose'} ${messages.administration || 'administration'}`}</option>
														{ administrations.map((value) => (
															<option key={`option_${value.uuid}`} value={value.uuid}>{value.name}</option>
														))}
													</Filter>
													<Filter onChange={this.handleFilterChange} name="selectedDepartment" defaultValue={this.state.selectedDepartment} >
														<option value="">{`${messages.choose || 'choose'} ${messages.department || 'department'}`}</option>
														{ departments.map((value) => (
															<option key={`option_${value.uuid}`} value={value.uuid}>{value.name}</option>
														))}
													</Filter>
												</>
											}
										</FilterGroup>
									</Col>
								</Row>
							</Toolbox>
						</SecureContent>
						<Thead>
							<Tr
								className="text-capitalize"
								data={['', ...this.fields]}
								layout={this.layout}
								sortBy={this.state.sort}
								sortMethod={this.state.sort_method}
								onClick={this.handleSortChange}
							/>
						</Thead>
						<Tbody refreshing={this.state.refreshing}>
							<Tr
								data={data ? data : {}}
								layout={this.layout}
								order={this.fields}
								badge_colors={this.badge_colors}
							>
								<Button type="view" title={messages.view || 'view'} onClick={(data) => {this.viewDetails(data.index)}} />
								<Button type="edit" title={messages.edit || 'edit'} onClick={(data) => {this.handleSelect(data.index)}} />
								{ (onlyPending && this.props.user.role === roles.EDITOR) &&
									<Button type="user-plus" title={messages.assign || 'assign'} onClick={(data) => {this.setState({assign: data})}} />
								}
							</Tr>
						</Tbody>
						<Pagination
							className="mx-auto"
							page={info.page}
							total={info.total_pages}
							onClick={(page) => {
								if (page !== info.page)
									this.handlePageChange(page);
							}}
						/>
					</Table>
				</Col>
				{ this.state.isViewOpen &&
					<View
						isOpen={this.state.isViewOpen}
						toggle={() => this.setState({isViewOpen: !this.state.isViewOpen})}
						data={this.props.viewData}
					/>
				}
				{ this.state.assign &&
					<AssignModal
						isOpen={this.state.assign ? true : false}
						toggle={() => {
							this.setState({assign: null});
							this.fetchData();
						}}
						data={this.state.assign}
					/>
				}
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.user,
	list: state.list,
	pending: state.list.application.pending,
	data: getData(state, 'application'),
	info: getInfo(state, 'application'),
	viewData: state.update.response,
	http_status: state.update.status,
	i18n: state.i18n,
	user: state.profile.user,
});

ApplicationList.propTypes = {
	onlyPending: PropTypes.bool, // Displays only pending applications for logged in reviewer
	withoutToolBox: PropTypes.bool // Does not display the toolbox
};

ApplicationList = connect(mapStateToProps)(ApplicationList);

export default ApplicationList;
