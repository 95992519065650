import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'settings',
		role: roles.ADMIN,
		children: [
			{
				name: 'pdf templates',
				url: StaticRoutes.PdfTemplates,
				icon: 'fa fa-file-pdf-o',
				role: roles.ADMIN,
			},
		],
	},
	{
		name: 'submission',
		url: StaticRoutes.Apply,
		role: [roles.GUEST, roles.AUTHORIZED],
	},
	{
		name: 'tracking',
		url: StaticRoutes.MyApplications,
		role: [roles.AUTHORIZED],
	},
	{
		name: 'applications',
		url: StaticRoutes.ApplicationRootAdmin,
		role: roles.REVIEWER,
		icon: 'icon-notebook',
		children: [
			// {
			// 	name: 'list',
			// 	icon: 'cui-list icons',
			// 	url: StaticRoutes.ApplicationList,
			// 	role: roles.REVIEWER,
			// },
			{
				name: 'pending',
				icon: 'icon-clock',
				url: StaticRoutes.PendingApplications,
				role: [roles.EDITOR, roles.REVIEWER]
			},
			{
				name: 'submissions',
				icon: 'fa fa-database',
				url: StaticRoutes.Metadata,
				role: roles.REVIEWER,
			}
		],
	},
	{
		name: 'submission',
		icon: 'cui-task icons',
		url: StaticRoutes.ApplyAdmin,
		role: roles.REVIEWER
	}
];

export default menu;
