import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
	Row,
	Col,
	Card,
	CardBody,
	Button,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Form,
} from 'reactstrap';
import pathToRegexp from 'path-to-regexp';

import { StaticRoutes } from '../../model/routes';
import { clearTimeout, logout } from 'core/ducks/profile';
import T from 'modules/i18n';

class LoginForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: ''
		}

		this.usernameRef = React.createRef();
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.clearTimeout = this.clearTimeout.bind(this);
	}

	componentDidMount() {
		this.usernameRef.current.focus();
	}

	handleChange(event) {
		const target = event.target;
		this.setState({
			[target.name]: target.type === 'checkbox'
				? target.checked
				: target.value
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		this.props.submit({
			username: this.state.username,
			password: this.state.password,
		}).then(() => {
			if (this.props.referrer) {
				let toPath = pathToRegexp.compile(this.props.referrer);
				let path = toPath({});
				this.props.history.replace(path);
			}
		}).catch(() => {});
	}

	clearTimeout() {
		this.props.dispatch( clearTimeout() );
		this.props.dispatch( logout() );
	}

	render() {

		const { messages } = this.props.i18n || {messages: {}};

		const form = (
			<Form onSubmit={this.handleSubmit}>
				<InputGroup className="mb-3">
					<InputGroupAddon addonType="prepend">
						<InputGroupText>
							<i className="icon-user"></i>
						</InputGroupText>
					</InputGroupAddon>
					<input
						ref={this.usernameRef}
						type="text"
						className="form-control"
						placeholder={messages.username || 'username'}
						value={this.state.username}
						onChange={this.handleChange}
						name="username"
						required
					/>
				</InputGroup>
				<InputGroup className="mb-4">
					<InputGroupAddon addonType="prepend">
						<InputGroupText>
							<i className="icon-lock"></i>
						</InputGroupText>
					</InputGroupAddon>
					<Input
						type="password"
						placeholder={messages.password || 'password'}
						value={this.state.password}
						onChange={this.handleChange}
						name="password"
						autoComplete="off"
						required
					/>
				</InputGroup>
				<Row>
					<Col xs="7">
						<Button
							color="primary"
							className="px-4"
							type="Submit"
						>
							<T>login</T>
						</Button>
						{ this.props.isGoogleAuthEnabled &&
							<a href="/api/oauth" className="ml-3" title={messages.login_with_google || 'Login with Google'}><i className="fa fa-google"/></a>
						}
						{ this.props.isGSISAuthEnabled &&
							<a href="/api/gsis" className="ml-3">TaxisNet</a>
						}
					</Col>
					<Col xs="5" className="text-right">
						{ this.props.modal
							? <p role="link" onClick={this.clearTimeout} className="px-0"><T>login as another user</T></p>
							: <Link to={StaticRoutes.PassRecovery} className="px-0" ><T>forgot password</T>?</Link>
						}
					</Col>
				</Row>
			</Form>
		);

		let message = this.props.notifications[this.props.notifications.length-1];

		if (this.props.modal) {
			return (
				<div>
					<Row>
						<Col xs="9" md="8" lg="8">
							{ message &&
								<span className={message.type}>
									<T>{message.body}</T>
								</span>
							}
						</Col>
					</Row>
					{form}
				</div>
			);
		}

		return (
			<Card className="p-4">
				<CardBody>
					<h1><T>login</T></h1>
					<p className="text-muted"><T>sign in to your account</T></p>
					<Row>
						<Col xs="9" md="8" lg="8">
							{ message &&
								<span className={message.type}>
									<T>{message.body}</T>
								</span>
							}
						</Col>
					</Row>
					{form}
				</CardBody>
			</Card>
		);
	}

}

const mapStateToProps = (state) => ({
	multilingual: state.ui.multilingual,
	i18n: state.i18n,
	isGSISAuthEnabled: state.ui.settings.values.isGSISAuthEnabled,
	isGoogleAuthEnabled: state.ui.settings.values.isGoogleAuthEnabled,
});

LoginForm = connect(mapStateToProps)(LoginForm);

export default withRouter(LoginForm);
