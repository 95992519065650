import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';

import { getData, updateData } from 'core/ducks/update';

class AssignToDepartment extends Component {

	constructor(props) {
		super(props);
		this.state = {
			administrations: [],
			departments: [],
			activeAdministration: '',
			activeDepartment: '',
			pending: true,
		};

		this.fetchAdministrations = this.fetchAdministrations.bind(this);
		this.fetchDepartments = this.fetchDepartments.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.fetchAdministrations();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.activeAdministration !== this.state.activeAdministration)
			this.fetchDepartments();
	}

	fetchAdministrations() {
		const { data } = this.props;
		this.props.dispatch(
			getData('administration')
		).then((administrations) => {
			let activeAdministration = administrations.filter(value => value.name===data.administration);
			activeAdministration = activeAdministration.length > 0 ? activeAdministration[0].uuid : '';
			this.setState({administrations, pending: false, activeAdministration});
		}).catch((err) => {
			console.warn(err);
		});
	}

	fetchDepartments() {
		const { data } = this.props;
		if (this.state.activeAdministration === '') {
			this.setState({activeDepartment: ''});
			return;
		}
		this.props.dispatch(
			getData(`department/administration/${this.state.activeAdministration}`)
		).then((departments) => {
			let activeDepartment = departments.filter(value => value.name===data.department);
			activeDepartment = activeDepartment.length > 0 ? activeDepartment[0].uuid : '';
			this.setState({departments, activeDepartment});
		}).catch((err) => {
			console.warn(err);
		});
	}

	handleChange(event) {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { activeAdministration, activeDepartment } = this.state;
		const data = {administration: activeAdministration, department: activeDepartment};
		this.props.dispatch(
			updateData(`userDepartment/user/${this.props.data.uuid}`, data)
		).then(this.props.toggle).catch(err => {console.warn(err)});
	}

	render() {

		const { toggle, data } = this.props;
		const { administrations, departments, activeAdministration, activeDepartment } = this.state;

		return (
			<Modal isOpen={true} className="modal-md">
				<ModalHeader toggle={toggle}>{`Ανάθεση Χρήστη ${data.firstname} ${data.lastname}`}</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						<FormGroup row>
							<Label htmlFor="administration_select" sm={2} className="pt-4 text-primary">Διεύθυνση</Label>
							<Col sm={10}>
								<Input id="administration_select" type="select" value={activeAdministration} onChange={this.handleChange} name="activeAdministration">
									<option value="">---Επιλέξτε Διεύθυνση---</option>
									{ administrations.map(value => (
										<option key={`option_${value.uuid}`} value={value.uuid}>{value.name}</option>
									))}
								</Input>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label htmlFor="department_select" sm={2} className="pt-4 text-primary">Τμήμα</Label>
							<Col sm={10}>
								<Input id="department_select" type="select" value={activeDepartment} onChange={this.handleChange} name="activeDepartment">
									<option value="">---Επιλέξτε Τμήμα---</option>
									{ departments.map(value => (
										<option key={`option_${value.uuid}`} value={value.uuid}>{value.name}</option>
									))}
								</Input>
							</Col>
						</FormGroup>
						<Row>
							<Col className="text-right">
								<Button color="success" className="mr-2">Ανανέωση</Button>
								<Button color="warning" type="button" className="mr-2" onClick={toggle}>Ακύρωση</Button>
							</Col>
						</Row>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

AssignToDepartment = connect(null)(AssignToDepartment);

export default AssignToDepartment;
