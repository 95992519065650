import React, { Component } from 'react';
import Input from 'core/components/input';

import '../style/scale-input.scss';

class ScaleInput extends Component {

	constructor(props) {
		super(props);
		this.state = {
			value: props.value || '',
			valueInSelect: props.value || '',
			isOtherEnabled: false,
		};
		this.inputRef = React.createRef();

		this.handleChange = this.handleChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
	}

	handleChange(event) {
		const { value } = event.target;
		this.props.onChange(event);
		this.setState({value});
	}

	handleSelectChange(event) {
		const { value } = event.target;
		if (value === 'other') {
			this.setState({
				valueInSelect: value,
				value: '',
				isOtherEnabled: true,
			});
			this.props.onChange({
				...event,
				target: {...event.target, value: '', name: this.props.name}
			});
		} else {
			this.setState({
				valueInSelect: value,
				value,
				isOtherEnabled: false,
			});
			this.props.onChange({
				...event,
				target: {...event.target, value, name: this.props.name}
			});
		}
	}

	render() {

		const { value, valueInSelect, isOtherEnabled } = this.state;
		const { type, label, options, textForOther, onChange, ...otherProps } = this.props;

		return (
			<>
				<Input type="select" value={valueInSelect} onChange={this.handleSelectChange}>
					<option value="">--- Επιλέξτε {label} ---</option>
					{ Object.keys(options).map(key => (
						<option key={`scale_option_${key}`} value={key}>{options[key]}</option>
					))}
					<option value="other">{textForOther}</option>
				</Input>
				<Input
					type={isOtherEnabled ? 'text' : 'hidden'}
					id="scale_input_prepend"
					value="1 : "
					readOnly
					onClick={() => {this.inputRef.current.focus()}}
				/>
				<Input
					innerRef={this.inputRef}
					{...otherProps}
					type={isOtherEnabled ? 'number' : 'hidden'}
					id="scale_input_main"
					value={value}
					onChange={this.handleChange}
					placeholder=""
				/>
			</>
		);
	}

}

export default ScaleInput;
