import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, CustomInput } from 'reactstrap';

import { EditControl } from 'react-leaflet-draw';
import * as L from 'leaflet';
import 'leaflet-i18n';
import 'proj4leaflet';
import proj4 from 'proj4';
import MeasureControlDefault from 'react-leaflet-measure';
import { Map as LeafletMap, withLeaflet, TileLayer, FeatureGroup, WMSTileLayer, LayerGroup } from 'react-leaflet';
import { ReactLeafletSearch as ReactLeafletSearchDefault } from 'react-leaflet-search';

import { requestData, getData, getInfo } from 'core/ducks/list';
import { deleteData, getData as getFeature } from 'core/ducks/update';
import { buildPath } from 'core/model/lib/urlTools';
import {
	Filter, CustomFilter, FilterGroup, Table, Title, Button, Tbody, Thead, Toolbox, Tr, Search, Pagination, Checkbox
} from 'table';
import { ErrorPage } from 'core/views/pages';
import { Loading } from 'core/components';
import { ExportToCSV } from 'core/model/lib';
import T from 'modules/i18n';
import { toggleModal } from 'core/ducks/ui/modal';
import { metadata, applicationStatus, applicationStatusStyle } from '../../model/lib';
import { LeafletInfoControl, AdvancedView as View, AutocompleteFilter, LayersControl, CustomGeocoding } from '../../components';
import { StaticRoutes, DynamicRoutes } from '../../model/routes';
import { ViewUser } from 'users/views/modals/';
import Alert from 'core/views/modals/alert';

const MeasureControl = withLeaflet(MeasureControlDefault);
const ReactLeafletSearch = withLeaflet(ReactLeafletSearchDefault);
proj4.defs("EPSG:2100", "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs");

L._("Draw a polyline");
L._("Finish");
const myLocale = {
	"Draw a polyline": "Σχεδιάστε μία γραμμή",
	"Finish": "Ολοκλήρωση"
};
L.registerLocale('el', myLocale);
L.setLocale('el');

const getLayerType = (layer) => {
	if (layer instanceof L.Marker)
		return 'POINT';
	if ((layer instanceof L.Polyline) && !(layer instanceof L.Polygon))
		return 'LINESTRING';
	if (layer instanceof L.Polygon)
		return 'POLYGON';
	if (layer instanceof L.Circle)
		return 'CIRCLE';
};

const toWKT = (layer) => {
	const coords = [];
	const type = getLayerType(layer);
	let latlngs;
	switch (type) {
		case 'POLYGON':
			latlngs = layer.getLatLngs();
			latlngs.forEach((polygon, index) => {
				coords[index] = [];
				polygon.forEach(latlng => {
					let projected = proj4('EPSG:4326', 'EPSG:2100', [latlng.lng, latlng.lat]);
					coords[index].push(projected[0] + ' ' + projected[1]);
				});
			});
			if (coords.length === 0) {
				return null;
			} else if (coords.length === 1) {
				coords[0].push(coords[0][0]);
				return "POLYGON((" + coords[0].join(",") + "))";
			} else {
				const polygons = [];
				coords.forEach(polygon => {
					polygon.push(polygon[0]);
					polygons.push("(" + polygon.join(",") + ")");
				});
				return "MULTIPOLYGON((" + polygons.join(",") + "))";
			}


		case 'LINESTRING':
			latlngs = layer.getLatLngs();
			latlngs.forEach(latlng => {
				let projected = proj4('EPSG:4326', 'EPSG:2100', [latlng.lng, latlng.lat]);
				coords.push(projected[0] + ' ' + projected[1]);
			});
			return type + "(" + coords.join(",") + ")";

		default:
			let { lat, lng } = layer.getLatLng()
			let projected = proj4('EPSG:4326', 'EPSG:2100', [lng, lat]);
			return "POINT(" + projected[0] + " " + projected[1] + ")";
	}
};

class AttibuteTable extends Component {

	constructor(props) {
		super(props);
		const overlays = {
			ktimatologio: {
				checked: false,
				name: 'Κτηματολόγιο',
				style: { color: 'grey', opacity: 0.5 }
			},
			ot: {
				checked: false,
				name: 'Ο.Τ.',
				style: { color: 'grey', opacity: 1 }
			}
		};
		const status = Object.keys(applicationStatusStyle).reduce((obj, key) => ({
			...obj,
			[key]: {
				checked: key === 'rejected' ? false : true,
				name: props.i18n.messages[key],
				style: { color: applicationStatusStyle[key].color, opacity: 1 }
			}
		}), ({}));
		this.initialState = {
			page: 1,
			query: '',
			filters: {
				kind: '',
				scale: '',
				area: '',
				community: '',
				ot: '',
				'application.status': '',
				administration: '',
				department: '',
				internal: '',
			}
		}
		this.state = {
			httpStatus: 200,
			refreshing: false,
			cqlFilter: null,
			sort: 'date',
			sort_method: 'desc',
			allChecked: false,
			highlighted: [],
			included: [],
			excluded: [],
			isViewOpen: false,
			...this.initialState,
			categories: [],
			administrations: [],
			departments: [],
			communities: [],
			activeLayout: 0,
			history: {},
			overlays,
			status,
		};
		this.layout = [];
		this.layout[0] = Object.keys(metadata).reduce((obj, key) => ({
			...obj,
			[key]: { sortable: (key !== 'address' && key !== 'comment' && key !== 'status') }
		}), {});
		this.layout[0].date.type = 'date';
		this.badge_colors = Object.keys(applicationStatusStyle).reduce((obj, key) => ({
			...obj,
			[key]: applicationStatusStyle[key].badge,
		}), {});
		this.layout[1] = {
			registration_number: { sortable: true },
			internal: { sortable: true, type: 'boolean' },
			author: { sortable: true },
			created: { type: 'date', sortable: true },
			modified: { type: 'date', sortable: true },
			status: { type: 'translatable' },
			administration: { sortable: true, type: 'text' },
			department: { sortable: true, type: 'text' },
			reviewer: { sortable: true }
		};
		this.fields = ['author', 'registration_number', 'internal', 'created', 'modified', 'reviewer', 'status', 'administration', 'department'];

		this.mapRef = React.createRef();
		this.editControlRef = React.createRef();
		this.actions = bindActionCreators({ deleteData, toggleModal }, props.dispatch);

		this.fetchData = this.fetchData.bind(this);
		this.createURL = this.createURL.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleQueryChange = this.handleQueryChange.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this.requestHistory = this.requestHistory.bind(this);
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(getFeature('planCategories/fields/category')).then(categories => {
			this.setState({ categories });
		}).catch((httpStatus) => {
			console.warn(httpStatus);
		});
		dispatch(getFeature('administration')).then(administrations => {
			this.setState({ administrations });
		}).catch(httpStatus => {
			console.warn(httpStatus);
		});
		dispatch(getFeature('department')).then(departments => {
			this.setState({ departments });
		}).catch(httpStatus => {
			console.warn(httpStatus);
		});
		dispatch(getFeature('administrative/scope/dk/fields/name/sort/name')).then(communities => {
			this.setState({ communities });
		}).catch(httpStatus => {
			console.warn(httpStatus);
		});
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.cqlFilter !== this.state.cqlFilter)
			this.fetchData();
	}

	fetchData() {
		let url = this.createURL();
		url = encodeURI(url);
		this.props.dispatch(
			requestData('metadata', url, false)
		).then(() => {
			this.setState({
				included: !this.state.cqlFilter ? [] : [...Object.values(this.props.data).map(entry => entry.token).filter(token => !this.state.excluded.includes(token))]
			});
		});
	}

	createURL(scope = '') {
		let { query, sort, sort_method, page, cqlFilter } = this.state;
		const status = Object.keys(this.state.status).filter(key => this.state.status[key].checked).map(value => `'${value}'`).join(', ');
		let url = cqlFilter
			? `/geoserver/wfs/metadata?service=WFS&request=GetFeature&typeName=metadata:bbox&layers=bbox&version=1.1.1&cql_filter=${cqlFilter} AND "status" IN (${status})&outputFormat=JSON&page=${page}&sort=${sort}&sort_method=${sort_method}`
			: `/api/metadata/page/${page}/sort/${sort}/sort_method/${sort_method}`;

		const request = this.createRequest(this.state.filters);
		if (query !== '')
			url += `${cqlFilter ? '&query=' : '/query/'}${query}`;
		if (request !== '')
			url += `${cqlFilter ? '&fq=' : '/fq/'}${request}`;
		if (scope === 'csv')
			url += cqlFilter ? '&limit=10000' : '/limit/10000';
		return url;
	}

	createRequest(filters) {
		let request = [];
		Object.keys(filters).forEach(key => {
			if (filters[key] !== '') {
				let value = `${key}:${filters[key]}`
				request.push(value);
			}
		});
		return request.join(';');
	}

	handlePageChange(page) {
		this.setState({ page }, this.fetchData)
	}

	handleSortChange(sort) {
		if (sort === this.state.sort) {
			this.setState({ sort_method: this.state.sort_method === 'asc' ? 'desc' : 'asc' }, this.fetchData);
		} else {
			this.setState({ sort }, this.fetchData);
		}
	}

	_createCQLFilter = (layer) => {
		const wkt = toWKT(layer);
		const type = getLayerType(layer);
		switch (type) {
			case 'CIRCLE':
				return `DWITHIN("bbox", ${wkt}, ${layer._mRadius}, meters)`;

			default:
				return `INTERSECTS("bbox", ${wkt})`;
		}
	}

	_onCreated = (e) => {
		let { layer } = e;
		const cqlFilter = this._createCQLFilter(layer);
		this.setState({
			cqlFilter,
		});
	}

	_onEdited = (e) => {
		e.layers.eachLayer(layer => {
			const cqlFilter = this._createCQLFilter(layer);
			this.setState({
				cqlFilter,
			});
		});
	}

	_onDeleted = (e) => {
		this.setState({
			cqlFilter: null,
		});
	}

	_removeAllLayers = () => {
		const layerContainer = this.editControlRef.current.leafletElement.options.edit.featureGroup;
		const layers = layerContainer._layers;
		Object.keys(layers).forEach(id => {
			const layer = layers[id];
			layerContainer.removeLayer(layer);
		});
	}

	onLeafletViewControlClick = (ev, map) => {
		const options = this.state.cqlFilter ? { cql_filter: this.state.cqlFilter, propertyName: 'token' } : { propertyName: 'token' };
		this.featureInfoRequest(ev, map, 'bbox', '/geoserver/wms/metadata', options).then(response => {
			this.parseFeatureInfo(response);
		});
	}

	onLeafletInfoControlClick = (ev, map) => {
		this.featureInfoRequest(ev, map, 'topo:Aggregate', '/geoserver/wms/topo').then(response => {
			if (!response.features)
				return;
			let results = response.features.reduce((obj, feature) => ({
				...obj,
				[feature.properties.application]: obj[feature.properties.application]
					? [...obj[feature.properties.application], feature.id.split('.')[0]]
					: [feature.id.split('.')[0]]
			}), {});
			let content = [];
			Object.keys(results).forEach((token, index) => {
				let subcontent = results[token].map((result, index2) => `<p>${index2 + 1}. <b>${result}</b></p>`)
				subcontent = subcontent.join('\n');
				const url = buildPath(DynamicRoutes.ApplicationAdmin, [token]);
				let header = `<a target="_blank" href="${url}">Αποτέλεσμα ${index + 1}</a>`;
				subcontent = header + subcontent;
				content.push(subcontent);
			});
			content = content.join('\n');
			if (content === '')
				return;
			L.popup()
				.setLatLng(ev.latlng)
				.setContent(content)
				.openOn(map);
		});
	}

	featureInfoRequest = (ev, map, layerName, baseUrl, customOptions = {}) => {
		const point = map.latLngToContainerPoint(ev.latlng, map.getZoom());
		const size = map.getSize();
		let mapLayers = Object.values(map._layers).filter(layer => {
			return (layer.options && layer.options.layers && layer.options.layers === layerName);
		});
		mapLayers = [...mapLayers];
		const promise = new Promise((resolve, reject) => {
			if (mapLayers[0]) {
				let options = { ...mapLayers[0].options, ...mapLayers[0].wmsParams };
				const { styles, transparent, version, format } = options;
				const params = {
					request: 'GetFeatureInfo',
					service: 'WMS',
					srs: 'EPSG:4326',
					height: size.y,
					width: size.x,
					info_format: 'application/json',
					styles,
					transparent,
					version,
					format,
					layers: layerName,
					bbox: map.getBounds().toBBoxString(),
					query_layers: layerName,
					feature_count: 100,
					...customOptions,
				};
				params[version === '1.3.0' ? 'i' : 'x'] = point.x;
				params[version === '1.3.0' ? 'j' : 'y'] = point.y;
				const url = baseUrl + L.Util.getParamString({ ...params }, baseUrl, true);
				this.props.dispatch(
					getFeature(url, false)
				).then(response => {
					resolve(response);
				}).catch(err => {
					console.warn(err);
					reject();
				});
			} else {
				reject();
			}
		});
		return promise;
	}

	parseFeatureInfo = (json) => {
		const highlighted = json.features.map(feature => feature.properties.token);
		if (this.state.highlighted.find(elem => elem === highlighted))
			return;
		this.setState({
			highlighted: [...this.state.highlighted, ...highlighted]
		});
	}

	handleCheckboxChange = (args) => {
		const { token, checked } = args;
		const { included, excluded, highlighted, cqlFilter } = this.state;
		const index = included.indexOf(token);
		const index2 = excluded.indexOf(token);
		const index3 = highlighted.indexOf(token);
		const state = {};
		if (checked) {
			if (index === -1)
				state.included = [...included, token];
			if (index2 !== -1 && cqlFilter)
				state.excluded = [
					...excluded.slice(0, index2),
					...excluded.slice(index2 + 1),
				];
		} else {
			if (index !== -1)
				state.included = [
					...included.slice(0, index),
					...included.slice(index + 1),
				];
			if (index2 === -1 && cqlFilter)
				state.excluded = [...excluded, token];
			if (index3 !== -1)
				state.highlighted = [
					...highlighted.slice(0, index3),
					...highlighted.slice(index3 + 1),
				];
		}
		this.setState({ ...state });
	}

	handleAllCheck = (event) => {
		const { checked } = event.target;
		this.setState({
			allChecked: checked,
			included: checked ? [...Object.values(this.props.data).map(entry => entry.token)] : [],
		});
	}

	focusOnMap = (data) => {
		if (!this.state.highlighted.includes(data.token))
			this.setState({
				highlighted: [...this.state.highlighted, data.token]
			});
		this.handleCheckboxChange({ token: data.token, checked: true });
		const bbox = JSON.parse(data.bbox);
		let bounds = new L.Proj.geoJson(bbox);
		bounds = bounds.getBounds();
		this.mapRef.current.leafletElement.flyToBounds(bounds);
	}

	viewDetails(token) {
		const url = `application/token/${token}`;
		this.props.dispatch(getFeature(url)).then(() => {
			this.setState({ isViewOpen: true });
		}).catch((err) => {
			console.warn(err);
		});
	}

	handleEditButtonPress(token) {
		const url = DynamicRoutes.Application;
		const path = buildPath(url, [token]);
		this.props.history.push(path);
	}

	handleDeleteButtonPress(token) {
		this.actions.toggleModal(true,
			<Alert
				toggle={() => { this.actions.toggleModal() }}
				title="drop confirm"
				message="do you wish to continue"
				onConfirm={() => {
					this.actions.deleteData(`application/token/${token}`).then(() => {
						this.fetchData();
					}).catch((err) => {
						console.warn(err);
					});
				}}
			/>
		)
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState
		}, this.fetchData);
		this.props.history.push(StaticRoutes.Metadata);
	}

	handleFilterChange(event) {
		this.setState({
			filters: {
				...this.state.filters,
				[event.target.name]: event.target.value,
			},
			page: 1
		}, this.fetchData);
	}

	handleQueryChange(event) {
		this.setState({
			query: event.target.value,
			page: 1,
		}, this.fetchData);
	}

	handleCSVExport() {
		const { messages } = this.props.i18n || { messages: {} };
		const url = this.createURL('csv');
		let fields = [...this.fields, ...Object.keys(metadata), 'lat', 'lon', 'bbox'];
		this.props.dispatch(requestData('csv', url, false)).then(() => {
			let data = Object.keys(this.props.list.csv.data.values).map((index) => {
				let row = this.props.list.csv.data.values[index];
				return fields.map((col) => {
					if (col === 'bbox') {
						let coordinates = JSON.parse(row[col]).coordinates[0].map(pair => pair.join(' '));
						coordinates = coordinates.join(', ');
						return `POLYGON((${coordinates}))`;
					}
					return (this.layout[col] && this.layout[col].type === 'translatable' && messages[row[col]]) ? messages[row[col]] : row[col];
				});
			});
			fields = this.fields.map((field) => {
				return messages[field] ? messages[field] : field;
			});
			fields = [...fields, ...Object.values(metadata).map(value => value.label), 'lat', 'lon', 'bbox'];
			let csv = new ExportToCSV('submits.csv', fields, data);
			csv.createLink();
		});
	}

	requestHistory(data) {
		const { history } = this.state;
		if (!history[data.token])
			this.props.dispatch(
				getFeature(`metadata/history/${data.token}`)
			).then(response => {
				this.setState({
					history: {
						...history,
						[data.token]: response,
					}
				});
			}).catch(err => {
				console.warn(err);
			});
	}

	handleOverlayChange = (overlay) => {
		this.setState({
			overlays: {
				...this.state.overlays,
				...overlay
			},
		});
	}

	handleStatusChange = (status) => {
		this.setState({
			status: {
				...this.state.status,
				...status
			}
		}, () => {
			this.fetchData();
		});
	}

	render() {

		const { data, info, list } = this.props;
		if (this.state.httpStatus !== 200)
			return (<ErrorPage status={this.state.httpStatus} />);
		if (this.props.pending || !data)
			return (<Loading />);
		if (list.application.status !== 200 && list.application.status !== '')
			return (<ErrorPage status={list.application.status} />);
		const shapeOptions = { fill: false, color: '#f10606', dashArray: '10, 10' };
		const tokens = this.state.highlighted.map(elem => `'${elem}'`).join(',');
		const includedTokens = this.state.included.map(elem => `'${elem}'`).join(',');
		const excludedTokens = this.state.excluded.map(elem => `'${elem}'`).join(',');
		const { messages } = this.props.i18n || { messages: {} };

		let cqlFilter = [];
		if (this.state.cqlFilter) {
			cqlFilter.push(this.state.cqlFilter);
			let set = [];
			if (excludedTokens.length > 0)
				set.push(excludedTokens);
			if (tokens.length > 0)
				set.push(tokens);
			set = set.join(',')
			if (set && set !== '')
				cqlFilter.push(`"token" NOT IN (${set})`);
		} else {
			if (includedTokens.length > 0)
				cqlFilter.push(`"token" IN (${includedTokens})`)
		}
		cqlFilter = cqlFilter.join(' AND ');

		const { activeLayout, administrations, departments, overlays } = this.state;

		const status = Object.keys(this.state.status).filter(key => this.state.status[key].checked).map(value => `'${value}'`).join(', ');

		return (
			<React.Fragment>
				<Row>
					<Col>
						<LeafletMap ref={this.mapRef} style={{ width: 100 + '%', height: 350 + 'px' }} minZoom={3} maxZoom={19} zoom={10} center={[37.983810, 23.727539]}>
							<TileLayer
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
							/>
							<MeasureControl
								position="topleft"
								primaryLengthUnit="meters"
								secondaryLengthUnit="kilometers"
								primaryAreaUnit="sqmeters"
								secondaryAreaUnit="hectares"
								activeColor="#db4a29"
								completedColor="#9b2d14"
							/>
							<ReactLeafletSearch
								position="topleft"
								inputPlaceholder="x,y ή ΟΤ (χωριζόμενα με ;) ή δ/νση"
								customProvider={CustomGeocoding}
								zoom={15}
							/>
							<LayersControl position="bottomleft" layers={this.state.overlays} onLayerChange={this.handleOverlayChange} />
							<LayersControl position="bottomleft" layers={this.state.status} onLayerChange={this.handleStatusChange} fontImg="history" />
							<FeatureGroup ref={this._onFeatureGroupReady}>
								<EditControl
									ref={this.editControlRef}
									position="topright"
									draw={{
										polygon: { showLength: true, showArea: true, shapeOptions },
										polyline: { shapeOptions },
										marker: true,
										circlemarker: false,
										rectangle: { showRadius: true, metric: ['km', 'm'], shapeOptions },
										circle: { shapeOptions }
									}}
									onCreated={this._onCreated}
									onEdited={this._onEdited}
									onDeleted={this._onDeleted}
									onDrawStart={this._removeAllLayers}
								/>
							</FeatureGroup>
							<LayerGroup>
								{overlays.ktimatologio.checked &&
									<WMSTileLayer
										layers="KTBASEMAP"
										url="http://gis.ktimanet.gr/wms/wmsopen/wmsserver.aspx?"
										opacity={overlays.ktimatologio.style.opacity}
									/>
								}
								{overlays.ot.checked &&
									<WMSTileLayer
										url="/geoserver/wms/administrative"
										layers="ot"
										format="image/png"
										transparent={true}
										maxZoom={19}
										minZoom={13}
									/>
								}
							</LayerGroup>
							<LayerGroup>
								{
									this.state.highlighted.length > 0 ?
										<React.Fragment>
											<WMSTileLayer
												key="layer1"
												url="/geoserver/wms/metadata"
												format="image/png"
												transparent={true}
												layers="bbox"
												cql_filter={cqlFilter + ' AND "status" IN (' + status + ')'}
											/>
											<WMSTileLayer
												key="layer3"
												url="/geoserver/wms/metadata"
												format="image/png"
												transparent={true}
												layers="bbox"
												styles="bounding_box_highlighted"
												cql_filter={'"token" IN (' + tokens + ')'}
											/>
											<WMSTileLayer
												key="layer4"
												url="/geoserver/wms/topo"
												format="image/png"
												transparent={true}
												layers="topo:Aggregate"
												cql_filter={'"application" IN (' + tokens + ')'}
											/>
										</React.Fragment>
										:
										<WMSTileLayer
											key="layer5"
											url="/geoserver/wms/metadata"
											format="image/png"
											transparent={true}
											layers="bbox"
											cql_filter={cqlFilter + ' AND "status" IN (' + status + ')'}
										/>
								}
							</LayerGroup>
							<LeafletInfoControl id="leaflet_view_control" onClick={this.onLeafletViewControlClick} position="topright" title="Προβολή" icon="fa fa-eye" />
							<LeafletInfoControl id="leaflet_info_control" onClick={this.onLeafletInfoControlClick} position="topright" title="Πληροφορίες" icon="fa fa-info" />
						</LeafletMap>
					</Col>
				</Row>

				<Row>
					<Col>
						<Table>
							<Title>
								<T>submissions</T>
								<Button type="toolbox" title="filters" className="float-right" />
								<CustomInput
									type="switch"
									className="float-right mr-5"
									id="metadataSwitch"
									name="metadataSwitch"
									label="Περιγραφικά Δεδομένα"
									checked={activeLayout === 0}
									onChange={(e) => { this.setState({ activeLayout: e.target.checked ? 0 : 1 }) }}
								/>
							</Title>
							<Toolbox onReset={this.handleToolboxReset}>
								{activeLayout === 0 ?
									<React.Fragment>
										<Row>
											<Col xs="12" lg="9" className="form-group text-right py-0">
												<Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleQueryChange} name="query" />
											</Col>
											<Col xs="12" lg="3" className="form-group text-right py-0">
												<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
											</Col>
										</Row>
										<Row>
											<Col xs="12" lg="6" className="py-0">
												<FilterGroup>
													<CustomFilter onChange={this.handleFilterChange} name="scale" defaultValue={this.state.filters.scale}>
														<AutocompleteFilter id="scale_filter" value={this.state.filters.scale} onChange={this.handleFilterChange} name="scale" scope="scale" placeholder="Κλίμακα" renderMenuItemChildren={(value) => `1 : ${value}`} numeric={true} />
													</CustomFilter>
													<Filter onChange={this.handleFilterChange} name="kind" defaultValue={this.state.filters.kind}>
														<option value="">Επιλέξτε Είδος</option>
														{this.state.categories.map((category, index) => (
															<option key={`category_${index}`} value={category}>{category}</option>
														))}
													</Filter>
													<CustomFilter onChange={this.handleFilterChange} name="area" defaultValue={this.state.filters.area}>
														<AutocompleteFilter id="area_filter" value={this.state.filters.area} onChange={this.handleFilterChange} name="area" scope="area" placeholder="Περιοχή" />
													</CustomFilter>
												</FilterGroup>
											</Col>
											<Col xs="12" lg="6" className="py-0">
												<FilterGroup>
													<Filter onChange={this.handleFilterChange} name="community" defaultValue={this.state.filters.community}>
														<option value="">Επιλέξτε Δ.Κ.</option>
														{this.state.communities.map((community, index) => (
															<option key={`category_${index}`} value={community}>{community}</option>
														))}
													</Filter>
													<CustomFilter onChange={this.handleFilterChange} name="ot" defaultValue={this.state.filters.ot}>
														<AutocompleteFilter id="ot_filter" value={this.state.filters.ot} onChange={this.handleFilterChange} name="ot" scope="ot" placeholder="Ο.Τ." />
													</CustomFilter>
												</FilterGroup>
											</Col>
										</Row>
									</React.Fragment>
									:
									<Row>
										<Col xs="12" lg="6" className="py-0">
											<FilterGroup>
												<Filter onChange={this.handleFilterChange} name="application.status" defaultValue={this.state.filters.status} >
													<option value="">{`${messages.choose || 'choose'} ${messages.status || 'status'}`}</option>
													{applicationStatus.map((status) => (
														<option key={`option_${status}`} value={status}>{messages[status] || 'status'}</option>
													))}
												</Filter>
												<Filter onChange={this.handleFilterChange} name="application.administration" defaultValue={this.state.filters.administration} >
													<option value="">{`${messages.choose || 'choose'} ${messages.administration || 'administration'}`}</option>
													{administrations.map((value) => (
														<option key={`option_${value.uuid}`} value={value.uuid}>{value.name}</option>
													))}
												</Filter>
												<Filter onChange={this.handleFilterChange} name="application.department" defaultValue={this.state.filters.department} >
													<option value="">{`${messages.choose || 'choose'} ${messages.department || 'department'}`}</option>
													{departments.map((value) => (
														<option key={`option_${value.uuid}`} value={value.uuid}>{value.name}</option>
													))}
												</Filter>
												<Filter onChange={this.handleFilterChange} name="application.internal" defaultValue={this.state.filters.internal} >
													<option value="">Επιλέξτε Είδος Συντάκτη</option>
													<option value={true}>Δήμος</option>
													<option value={false}>Εξωτερικός</option>
												</Filter>
											</FilterGroup>
										</Col>
										<Col xs="12" lg="3" className="py-0">
											<Button type="csv" title={`${messages.export || 'export'} csv`} onClick={this.handleCSVExport}>
												<T>export</T> csv
											</Button>
										</Col>
										<Col xs="12" lg="3" className="form-group text-right py-0">
											<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
										</Col>
									</Row>
								}
							</Toolbox>
							<Thead>
								{activeLayout === 0 ?
									<tr>
										<th />
										{Object.keys(metadata).map((field, index) => (
											<th key={`th_${index}`}>
												{this.layout[activeLayout][field].sortable ?
													<span role="button" onClick={() => { this.handleSortChange(field) }}>
														{metadata[field].short + ' '}
														{(this.state.sort === field && this.state.sort_method === 'asc') && <i className="fa fa-sort-asc hidden" />}
														{(this.state.sort === field && this.state.sort_method === 'desc') && <i className="fa fa-sort-desc hidden" />}
													</span>
													:
													<span>{metadata[field].short}</span>
												}
											</th>
										))}
									</tr>
									:
									<Tr
										className="text-capitalize"
										data={['', ...this.fields]}
										layout={this.layout[1]}
										sortBy={this.state.sort}
										sortMethod={this.state.sort_method}
										onClick={this.handleSortChange}
									/>
								}
							</Thead>
							<Tbody refreshing={this.state.refreshing}>
								<Tr
									data={data ? data : {}}
									history={this.state.history}
									layout={this.layout[activeLayout]}
									badge_colors={this.badge_colors}
									order={activeLayout === 0 ? Object.keys(metadata) : this.fields}
									index="token"
								>
									<Checkbox allChecked={this.state.allChecked} list={this.state.included} onChange={this.handleCheckboxChange} className="mr-2" />
									<Button type="edit" title={messages.edit || 'edit'} onClick={(data) => { this.handleEditButtonPress(data.token) }} />
									<Button type="view" title={messages.view || 'view'} onClick={(data) => { this.viewDetails(data.token) }} />
									<Button type="search" pressed={this.state.highlighted} onClick={this.focusOnMap} />
									<Button type="repeat" condition={{ internal: true, obsolete: false }} color="warning" title={messages.revise || 'revise'} onClick={data => {
										this.props.history.push(buildPath(DynamicRoutes.ApplyWithParentAdmin, [data.token]));
									}} />
									<Button type="history" condition={'has_ancestors'} onClick={this.requestHistory} />
									<Button type="delete" title={messages.drop || 'drop'} onClick={(data) => { this.handleDeleteButtonPress(data.token) }} />
								</Tr>
							</Tbody>
							<Pagination
								className="mx-auto"
								page={info.page}
								total={info.total_pages}
								onClick={(page) => {
									if (page !== info.page)
										this.handlePageChange(page);
								}}
							/>
						</Table>
					</Col>
				</Row>
				{this.state.isViewOpen &&
					<View
						isOpen={this.state.isViewOpen}
						toggle={() => this.setState({ isViewOpen: !this.state.isViewOpen })}
						data={this.props.viewData}
						showUser={() => {
							this.props.dispatch(toggleModal(true,
								<ViewUser uuid={this.props.viewData.info.author_uuid} />
							));
						}}
					/>
				}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list,
	pending: state.list.metadata.pending,
	data: getData(state, 'metadata'),
	info: getInfo(state, 'metadata'),
	i18n: state.i18n,
	viewData: state.update.response,
});

AttibuteTable = connect(mapStateToProps)(AttibuteTable);

export default AttibuteTable;
