import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AsyncTypeahead as Typeahead } from 'react-bootstrap-typeahead';
import { getData } from 'core/ducks/update';

class AutocompleteFilter extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selected: [],
			value: props.value || '',
			isLoading: false,
			options: [],
		};
		this.typeaheadState = {
			disabled: false,
			dropup: false,
			flip: false,
			highlightOnlyResult: true,
			minLength: 0,
			open: undefined,
			selectHintOnEnter: true,
			emptyLabel: 'Δεν βρέθηκαν αποτελέσματα.',
			delay: 500,
			onSearch: this.requestResults,
			promptText: 'Ξεκινήστε να πληκτρολογείτε...'
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
				selected: this.props.value==='' ? [] : this.state.selected,
			});
		}

		if (prevState.selected !== this.state.selected)
			if (typeof this.props.onChange === 'function')
				this.props.onChange({
					target: {
						name: this.props.name,
						value: this.state.selected[0] || '',
					}
				});
	}

	requestResults = (query) => {
		this.setState({isLoading: true}, () => {
			this.props.dispatch(
				getData(`metadata/autocomplete/${this.props.scope}/query/${query}`)
			).then(options => {
				if (this.props.numeric)
					options = options.map(value => value.toString())
				this.setState({
					options
				}, () => {
					this.setState({isLoading: false});
				});
			});
		});
	}

	handleChange(selected) {
		this.setState({
			selected: [...selected],
			value: selected[0],
		});
	}

	handleInputChange(value) {
		this.setState({value});
	}

	render() {

		const { value, onChange, name, scope, ...otherProps } = this.props;

		return (
			<Typeahead
				{...this.typeaheadState}
				placeholder="Εισάγετε τα 2 πρώτα ψηφία..."
				selected={this.state.selected}
				onChange={this.handleChange}
				options={this.state.options}
				inputProps={{value: this.state.value}}
				onInputChange={this.handleInputChange}
				isLoading={this.state.isLoading}
				{...otherProps}
			/>
		);
	}
}

AutocompleteFilter = connect(null)(AutocompleteFilter);

export default AutocompleteFilter;
