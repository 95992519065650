import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Button, Form,
} from 'reactstrap';

import { getData, updateData } from 'core/ducks/update';

class AssignModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			reviewers: [],
			pending: true,
			reviewer: props.data.reviewer_uuid
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(
			getData('userDepartment')
		).then(reviewers => {
			this.setState({
				reviewers,
				pending: false,
			});
		}).catch(err => {
			console.warn(err);
			this.props.toggle();
		});
	}

	handleChange(event) {
		const { checked, value } = event.target;
		if (checked)
			this.setState({
				reviewer: value,
			})
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, toggle, data } = this.props;
		const { reviewer } = this.state;
		dispatch(
			updateData(`application/token/${data.index}`, {reviewer})
		).then(toggle);
	}

	render() {

		const { isOpen, toggle, data } = this.props;
		const { reviewers, pending } = this.state;

		if (pending)
			return null;

		return (
			<Modal isOpen={isOpen}>
				<ModalHeader toggle={toggle}>{data.status==='progressing' ? 'Ανάθεση' : 'Μετεχρέωση'}</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						{ reviewers.map(reviewer => (
							<FormGroup key={`reviewer_${reviewer.uuid}`} check >
								<Label check >
									<Input type="radio" checked={this.state.reviewer===reviewer.uuid} value={reviewer.uuid} onChange={this.handleChange} />{' '}
									{`${reviewer.firstname} ${reviewer.lastname}`}
								</Label>
							</FormGroup>
						))}
						<div className="text-right">
							<Button type="submit" color="warning" disabled={!this.state.reviewer}>Ανάθεση</Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

AssignModal = connect(null)(AssignModal);

export default AssignModal;
