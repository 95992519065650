import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row, Col, Card, CardHeader, CardBody, Form, FormGroup, InputGroupAddon, InputGroupText, Input, Label,
} from 'reactstrap';

import { Loading } from 'core/components';
import { getData, updateData } from 'core/ducks/update';
import { default as T, getLocale } from 'modules/i18n';
import { Editor } from 'editor';

const editorMenu = {
	application: ['registration_number', 'created', 'modified', 'status'],
	metadata: ['request', 'title', 'description', 'scale', 'kind', 'date', 'srs', 'area', 'address', 'comment'],
	review: ['corrections'],
};

class PdfTemplates extends Component {

	constructor(props) {
		super(props);

		this.state = {
			under_submit: false,
			content: {},
			activeContent: '',
			ready: false,
			locale: 'en',
		};
		this.submitRef = React.createRef();

		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(
			getData('PdfTemplate')
		).then(content => this.setState({
			content,
			ready: true,
			activeContent: Object.keys(content)[0],
			locale: this.props.dispatch(getLocale()),
		}))
		.catch(err => console.warn(err));
	}

	handleEditorChange(html) {
		this.setState({
			content: {
				...this.state.content,
				[this.state.activeContent]: html,
			},
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { activeContent, content } = this.state;
		this.props.dispatch(
			updateData(`PdfTemplate/scope/${activeContent}`, {html: content[activeContent]})
		);
	}

	render() {
		const { content, activeContent, ready, locale } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		if (!ready) return (<Loading/>);

		return (
			<Row>
				<Col>
					<Card>
						<CardHeader>
							<Row>
								<Col sm="8" className="py-0">
									<h3><T>pdf templates</T></h3>
								</Col>
								<Col sm="4" className="py-0 text-right">
									<Label className="mr-2 mb-0 d-flex">
										<span className="mt-2 mr-2 font-weight-bold"><T>type</T></span>
										<Input
											type="select"
											className="d-inline-block"
											value={activeContent}
											onChange={(e) => this.setState({activeContent: e.target.value})}
										>
											{Object.keys(content).map(scope => (
												<option key={`selection_${scope}`}>{scope}</option>
											))}
										</Input>
									</Label>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<Form onSubmit={this.handleSubmit}>
								<Row>
									<Col>
										<FormGroup>
											<Editor
												locale={locale}
												value={content[activeContent]}
												onChange={this.handleEditorChange}
												name="html"
												setup={(ed) => {
													ed.on('KeyDown', (e) => {
														if ((e.metaKey || e.ctrlKey) && ( String.fromCharCode(e.which).toLowerCase() === 's')) {
															e.preventDefault();
															this.submitRef.current.click();
														}
													});
													ed.addButton('forms', {
														type: 'menubutton',
														text: messages.insert_field || 'Insert field',
														icon: false,
														menu: [
															{
																text: messages.identifier || 'Identifier',
																onclick: () => {ed.insertContent('[[token.token]]')}
															},
															...Object.keys(editorMenu).map(key => ({
																text: messages[key] || key,
																menu: editorMenu[key].map(field => ({
																	text: messages[field] || field,
																	onclick: () => {ed.insertContent(`[[${key}.${field}]]`)}
																})),
															})),
														]
													});
												}}
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col className="text-right">
										<InputGroupAddon addonType="prepend" className="float-right">
											<InputGroupText><i className="fa fa-save mr-2"></i></InputGroupText>
												<button type="submit" className="btn btn-success" ref={this.submitRef}>
													 <T>save</T>
												</button>
										</InputGroupAddon>
									</Col>
								</Row>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
});

PdfTemplates = connect(mapStateToProps)(PdfTemplates);

export default PdfTemplates;
