import axios from 'axios';
import { checkCookie } from 'core/ducks/profile';
import { pushNotification } from 'core/ducks/notifications';


// Actions

const UPLOADING_STARTED = 'api/upload/SENDING';
const UPLOAD_FAILED = 'api/upload/FAILED';
const UPLOAD_CANCELED = 'api/upload/CANCELED';
const UPLOAD_COMPLETED = 'api/upload/COMPLETED';
const PROGESS_SET = 'api/upload/SET_PROGRESS';


// Initial state

const initialState = {
	pending: false,
	status: null,
	progress: 0,
	message: '',
	response: null,
};


// Reducer

export default (state=initialState, action) => {
	switch (action.type) {

		case UPLOADING_STARTED:
			return {
				...state,
				pending: action.pending,
			};

		case PROGESS_SET:
			return {
				...state,
				progress: action.progress,
			};

		case UPLOAD_FAILED:
		case UPLOAD_CANCELED:
		case UPLOAD_COMPLETED:
			return {
				progress: action.progress,
				pending: action.pending,
				status: action.status,
				response: action.response,
				message: action.message,
			};

		default:
			return state;
	}
}


// Action creators

const uploading = () => ({
	type: UPLOADING_STARTED,
	pending: true,
});

const setProgress = (progress) => ({
	type: PROGESS_SET,
	progress,
});

const failed = (status, message) => ({
	type: UPLOAD_FAILED,
	progress: 0,
	pending: false,
	status,
	response: null,
	message,
});

const canceled = () => ({
	type: UPLOAD_CANCELED,
	progress: 0,
	pending: false,
	status: null,
	response: null,
	message: ''
});

const completed = (response, status, message) => ({
	type: UPLOAD_COMPLETED,
	progress: 0,
	pending: false,
	status,
	response,
	message
});


// Thunk action creators

export let cancelUpload;

export const uploadData = (url, data, notify=true) => (dispatch) => {
	let promise = new Promise((resolve, reject) => {
		const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
		const CancelToken = axios.CancelToken;
		dispatch( checkCookie() ).then(() => {
			dispatch(uploading());
			axios.request( {
				method: "post",
				url: `/api/${url}`,
				credentials: 'same-origin',
				data,
				headers: {
					"Accept": "application/json",
					"Content-Type": "multipart/form-data",
					"X-Csrf-Token": token,
				},
				onUploadProgress: (p) => {
					dispatch(setProgress(Math.round((p.loaded / p.total) * 100)));
				},
				cancelToken: new CancelToken((c) => {
					cancelUpload = c;
				}),
			}).then(response => {
				dispatch(completed(response.data, response.status, response.statusText));
				if (notify)
					dispatch(pushNotification({body: "data uploaded", type: "success"}));
				resolve(response.data);
			}).catch(error => {
				if (axios.isCancel(error)) {
					dispatch(canceled());
					reject('Canceled');
				} else {
					const { response } = error;
					dispatch(failed(response.status, response.statusText));
					if (notify)
						dispatch(pushNotification({body: "action denied", type: "warning"}))
					reject(response.statusText);
				}
			});
		});
	});

	return promise;
}
