import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTags from 'react-tag-autocomplete';

import { getData } from 'core/ducks/update';

import '../style/tags.scss';

class CommunityInput extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tags: [],
			value: [],
			dk: [],
			pending: true,
		};

		this.handleDelete = this.handleDelete.bind(this);
		this.handleAddition = this.handleAddition.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(
			getData('administrative/scope/dk/fields/name/sort/name')
		).then(dk => {
			this.setState({
				dk: [...dk.map(value => ({id: value, name: value})), {id: 'Εκτός ορίων', name: 'Εκτός ορίων'}],
				pending: false,
			});			
			if (this.props.defaultValue) {
				const tags = this.props.defaultValue
					.filter(elem => dk.includes(elem))
					.map(val => ({id: val, name: val}));
				this.setState({tags});
			}
		}).catch(err => {
			this.setState({
				pending: false,
			});
			console.warn(err);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevState.tags) !== JSON.stringify(this.state.tags)) {
			let value = this.state.tags.map(tag => tag.name);
			this.setState({value});
		}

		if (prevState.value !== this.state.value && typeof this.props.onChange == 'function')
			this.props.onChange({target: {name: this.props.name, value: JSON.stringify(this.state.value)}});
	}

	handleDelete (i) {
		const tags = this.state.tags.slice(0);
		tags.splice(i, 1);
		this.setState({ tags });
	}

	handleAddition (tag) {
		const { value, tags } = this.state;
		const index = value.indexOf(tag.name);
		let newTags = index !== -1 ? [...tags.slice(0, index), ...tags.slice(index + 1)] : [...tags];
		newTags = [].concat(newTags, tag);
		this.setState({ tags: newTags });
	}

	render() {

		const { dk, pending, value } = this.state;
		if (pending)
			return null;

		const classNames = (this.props.required && value.length === 0) ? ' is-invalid' : '';

		return (
			<>
				<ReactTags
					tags={this.state.tags}
					suggestions={dk}
					placeholder="Επιλέξτε Δ.Κ."
					autofocus={false}
					allowNew={false}
					maxSuggestionsLength={8}
					minQueryLength={0}
					handleDelete={this.handleDelete}
					handleAddition={this.handleAddition}
					autoresize={false}
				/>
				<input type="hidden" className={'form-control' + classNames}/>
			</>
		);
	}
}

CommunityInput = connect(null)(CommunityInput);

export default CommunityInput;
