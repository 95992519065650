import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Button, Form, FormGroup } from 'reactstrap';

import { updateData, postData } from 'core/ducks/update';

class EditAdministration extends Component {

	constructor(props) {
		super(props);
		this.state = {
			value: props.uuid ? props.value : '',
			mounted: false,
		};
		this.inputRef = React.createRef();

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.setState({mounted: true});
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.mounted && this.state.mounted)
			this.inputRef.current.focus();
	}

	handleChange(event) {
		const { value } = event.target;
		this.setState({value});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { uuid } = this.props;
		const method = uuid ? updateData : postData;
		const url = uuid ? `administration/uuid/${uuid}` : 'administration';
		this.props.dispatch(
			method(url, {name: this.state.value}, false)
		).then((response) => {
			this.props.onSubmit(uuid ? null : response);
			this.props.toggle();
		}).catch((err) => {
			console.warn(err);
		});
	}

	render() {

		const { isOpen, toggle, uuid } = this.props;
		const { value } = this.state;

		return (
			<Modal isOpen={isOpen} className="modal-md">
				<ModalHeader toggle={toggle}>{uuid ? 'Επεξεργασία Διεύθυνσης' : 'Προσθήκη Νέας Διεύθυνσης'}</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						<FormGroup>
							<input className="form-control" ref={this.inputRef} placeholder="Τίτλος Διεύθυνσης" value={value} onChange={this.handleChange}/>
						</FormGroup>
						<div className="text-right">
							<Button color="primary" className="mx-2">{uuid ? 'Ανανέωση' : 'Προσθήκη'}</Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		);

	}
}

EditAdministration = connect(null)(EditAdministration);

export default EditAdministration;
